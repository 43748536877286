import dog from "../Assets/Images/australianDog.png";
import avt from "../Assets/Images/creatorAvatar.png";
import foodkitNftIMg from "../Assets/Images/foodkitNft.png";
export const superDogNFT = [
  //--------------------------------------------1 bella--------------------------------------------//
  {
    id: 3,
    name: "BELLA",
    image: "../../Assets/DeflyBall/images/chihuahua-bella/bella-p.png",
    superDogNftCreatorAvatar: avt,
    superDogNftCreatorName: "Jade Soow",
    superDogNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/bella/jsons/bella_chihuahua.json",
    r_qty: 19,
    bnb: 0.02,
    busd: 5,
    defly: 400,
  },
  {
    id: 59,
    name: "MARLEY",
    image: "../../Assets/DeflyBall/images/chihuahua-bella/marley-p.png",
    superDogNftCreatorAvatar: avt,
    superDogNftCreatorName: "Jack Hager",
    superDogNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/bella/jsons/marley_chihuahua.json",
    r_qty: 20,
    bnb: 0.08,
    busd: 18,
    defly: 750,
  },
  {
    id: 30,
    name: "MONTY",
    image: "../../Assets/DeflyBall/images/chihuahua-bella/monty-p.png",
    superDogNftCreatorAvatar: avt,
    superDogNftCreatorName: "Nina holland",
    superDogNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/bella/jsons/monty_chihuahua.json",
    r_qty: 45,
    bnb: 0.05,
    busd: 10,
    defly: 600,
  },
  {
    id: 29,
    name: "POLKA",
    image: "../../Assets/DeflyBall/images/chihuahua-bella/polka-p.png",
    superDogNftCreatorAvatar: avt,
    superDogNftCreatorName: "sabrina kate",
    superDogNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/bella/jsons/polka_chihuahua.json",
    r_qty: 34,
    bnb: 0.1,
    busd: 20,
    defly: 1000,
  },
  {
    id: 60,
    name: "RAINEY",
    image: "../../Assets/DeflyBall/images/chihuahua-bella/rainey-p.png",
    superDogNftCreatorAvatar: avt,
    superDogNftCreatorName: "Nina holland",
    superDogNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/bella/jsons/rainey_chihuahua.json",
    r_qty: 66,
    bnb: 0.07,
    busd: 15,
    defly: 650,
  },
  {
    id: 58,
    name: "TITTO",
    image: "../../Assets/DeflyBall/images/chihuahua-bella/titto-p.png",
    superDogNftCreatorAvatar: avt,
    superDogNftCreatorName: "sabrina kate",
    superDogNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/bella/jsons/titto_chihuahua.json",
    r_qty: 25,
    bnb: 0.03,
    busd: 8,
    defly: 500,
  },

  //--------------------------------------------2 poodlejasper--------------------------------------------//
  {
    id: 40,
    name: "DOBBY",
    image: "../../Assets/DeflyBall/images/poodle-jasper/dobby-p.png",
    superDogNftCreatorAvatar: avt,
    superDogNftCreatorName: "Jade Soow",
    superDogNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/jasper/jsons/dobby_poddle.json",
    r_qty: 25,
    bnb: 0.03,
    busd: 8,
    defly: 500,
  },
  {
    id: 41,
    name: "DORITO",
    image: "../../Assets/DeflyBall/images/poodle-jasper/dorito-p.png",
    superDogNftCreatorAvatar: avt,
    superDogNftCreatorName: "Jack Hager",
    superDogNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/jasper/jsons/dorito_poddle.json",
    r_qty: 34,
    bnb: 0.1,
    busd: 20,
    defly: 1000,
  },
  {
    id: 17,
    name: "FLUFFBALL",
    image: "../../Assets/DeflyBall/images/poodle-jasper/fluffball-p.png",
    superDogNftCreatorAvatar: avt,
    superDogNftCreatorName: "Nina holland",
    superDogNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/jasper/jsons/fluffball_poddle.json",
    r_qty: 66,
    bnb: 0.07,
    busd: 15,
    defly: 650,
  },
  {
    id: 8,
    name: "JASPER",
    image: "../../Assets/DeflyBall/images/poodle-jasper/jasper-p.png",
    superDogNftCreatorAvatar: avt,
    superDogNftCreatorName: "sabrina kate",
    superDogNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/jasper/jsons/jasper_poodle.json",
    r_qty: 19,
    bnb: 0.02,
    busd: 5,
    defly: 400,
  },
  {
    id: 18,
    name: "MAXY",
    image: "../../Assets/DeflyBall/images/poodle-jasper/maxy-p.png",
    superDogNftCreatorAvatar: avt,
    superDogNftCreatorName: "Nina holland",
    superDogNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/jasper/jsons/maxy_poddle.json",
    r_qty: 45,
    bnb: 0.05,
    busd: 10,
    defly: 600,
  },
  {
    id: 42,
    name: "PLUTO",
    image: "../../Assets/DeflyBall/images/poodle-jasper/pluto-p.png",
    superDogNftCreatorAvatar: avt,
    superDogNftCreatorName: "sabrina kate",
    superDogNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/jasper/jsons/pluto_poddle.json",
    r_qty: 20,
    bnb: 0.08,
    busd: 18,
    defly: 750,
  },
  //-------------------------------------------3-golden-lightning--------------------------------------------//

  {
    id: 39,
    name: "ALFIE",
    image: "../../Assets/DeflyBall/images/golden-lightning/alfie-p.png",
    superDogNftCreatorAvatar: avt,
    superDogNftCreatorName: "Jade Soow",
    superDogNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/lightning/jsons/alfie_retreiver.json",
    r_qty: 25,
    bnb: 0.03,
    busd: 8,
    defly: 500,
  },
  {
    id: 6,
    name: "LIGHTNING",
    image: "../../Assets/DeflyBall/images/golden-lightning/lightning-p.png",
    superDogNftCreatorAvatar: avt,
    superDogNftCreatorName: "Jack Hager",
    superDogNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/lightning/jsons/lightning_golden.json",
    r_qty: 19,
    bnb: 0.02,
    busd: 5,
    defly: 400,
  },
  {
    id: 16,
    name: "LUCKY",
    image: "../../Assets/DeflyBall/images/golden-lightning/lucky-p.png",
    superDogNftCreatorAvatar: avt,
    superDogNftCreatorName: "sabrina kate",
    superDogNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/lightning/jsons/lucky_retreiver.json",
    r_qty: 45,
    bnb: 0.05,
    busd: 10,
    defly: 600,
  },
  {
    id: 37,
    name: "OSLO",
    image: "../../Assets/DeflyBall/images/golden-lightning/oslo-p.png",
    superDogNftCreatorAvatar: avt,
    superDogNftCreatorName: "Nina holland",
    superDogNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/lightning/jsons/oslo_retreiver.json",
    r_qty: 34,
    bnb: 0.1,
    busd: 20,
    defly: 1000,
  },

  {
    id: 15,
    name: "RICK",
    image: "../../Assets/DeflyBall/images/golden-lightning/rick-p.png",
    superDogNftCreatorAvatar: avt,
    superDogNftCreatorName: "Nina holland",
    superDogNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/lightning/jsons/rick_retriever.json",
    r_qty: 20,
    bnb: 0.08,
    busd: 18,
    defly: 750,
  },
  {
    id: 38,
    name: "ZEKE",
    image: "../../Assets/DeflyBall/images/golden-lightning/zeke-p.png",
    superDogNftCreatorAvatar: avt,
    superDogNftCreatorName: "sabrina kate",
    superDogNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/lightning/jsons/zeke_retreiver.json",
    r_qty: 66,
    bnb: 0.07,
    busd: 15,
    defly: 650,
  },
  //--------------------------------------------4--------------------------------------------//
  {
    id: 28,
    name: "BINGO",
    image: "../../Assets/DeflyBall/images/shetland-rocco/bingo-p.png",
    superDogNftCreatorAvatar: avt,
    superDogNftCreatorName: "Jade Soow",
    superDogNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/rocco/jsons/bingo_shetland.json",
    r_qty: 45,
    bnb: 0.05,
    busd: 10,
    defly: 600,
  },
  {
    id: 55,
    name: "LUIS",
    image: "../../Assets/DeflyBall/images/shetland-rocco/luis-p.png",
    superDogNftCreatorAvatar: avt,
    superDogNftCreatorName: "Jack Hager",
    superDogNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/rocco/jsons/luis_shetland.json",
    r_qty: 25,
    bnb: 0.03,
    busd: 8,
    defly: 500,
  },
  {
    id: 27,
    name: "MARIO",
    image: "../../Assets/DeflyBall/images/shetland-rocco/mario-p.png",
    superDogNftCreatorAvatar: avt,
    superDogNftCreatorName: "Nina holland",
    superDogNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/rocco/jsons/mario_shetland.json",
    r_qty: 34,
    bnb: 0.1,
    busd: 20,
    defly: 1000,
  },
  {
    id: 57,
    name: "OSCAR",
    image: "../../Assets/DeflyBall/images/shetland-rocco/oscar-p.png",
    superDogNftCreatorAvatar: avt,
    superDogNftCreatorName: "sabrina kate",
    superDogNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/rocco/jsons/oscar_shetland.json",
    r_qty: 66,
    bnb: 0.07,
    busd: 15,
    defly: 650,
  },
  {
    id: 10,
    name: "ROCCO",
    image: "../../Assets/DeflyBall/images/shetland-rocco/rocco-p.png",
    superDogNftCreatorAvatar: avt,
    superDogNftCreatorName: "Nina holland",
    superDogNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/rocco/jsons/rocco_shetland.json",
    r_qty: 19,
    bnb: 0.02,
    busd: 5,
    defly: 400,
  },
  {
    id: 56,
    name: "VINNY",
    image: "../../Assets/DeflyBall/images/shetland-rocco/vinny-p.png",
    superDogNftCreatorAvatar: avt,
    superDogNftCreatorName: "sabrina kate",
    superDogNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/rocco/jsons/vinny_shetland.json",
    r_qty: 20,
    bnb: 0.08,
    busd: 18,
    defly: 750,
  },
  //------------------------------------------------5----------------------------------------//
  {
    id: 47,
    name: "CLEO",
    image: "../../Assets/DeflyBall/images/australian-romeo/cleo-p.png",
    superDogNftCreatorAvatar: avt,
    superDogNftCreatorName: "Jade Soow",
    superDogNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/romeo/data/cleo_shepherd.json",
    r_qty: 34,
    bnb: 0.1,
    busd: 20,
    defly: 1000,
  },
  {
    id: 48,
    name: "JUNO",
    image: "../../Assets/DeflyBall/images/australian-romeo/juno-p.png",
    superDogNftCreatorAvatar: avt,
    superDogNftCreatorName: "Jack Hager",
    superDogNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/romeo/data/juno_shepherd.json",
    r_qty: 66,
    bnb: 0.07,
    busd: 15,
    defly: 650,
  },
  {
    id: 22,
    name: "LOBO",
    image: "../../Assets/DeflyBall/images/australian-romeo/lobo-p.png",
    superDogNftCreatorAvatar: avt,
    superDogNftCreatorName: "Nina holland",
    superDogNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/romeo/data/lobo_shepherd.json",
    r_qty: 20,
    bnb: 0.08,
    busd: 18,
    defly: 750,
  },
  {
    id: 21,
    name: "MUFFIN",
    image: "../../Assets/DeflyBall/images/australian-romeo/muffin-p.png",
    superDogNftCreatorAvatar: avt,
    superDogNftCreatorName: "sabrina kate",
    superDogNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/romeo/data/muffin_shepherd.json",
    r_qty: 45,
    bnb: 0.05,
    busd: 10,
    defly: 600,
  },
  {
    id: 46,
    name: "OLIVER",
    image: "../../Assets/DeflyBall/images/australian-romeo/oliver-p.png",
    superDogNftCreatorAvatar: avt,
    superDogNftCreatorName: "Nina holland",
    superDogNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/romeo/data/oliver_shepherd.json",
    r_qty: 25,
    bnb: 0.03,
    busd: 8,
    defly: 500,
  },

  {
    id: 1,
    name: "ROMEO",
    image: "../../Assets/DeflyBall/images/australian-romeo/romeo-p.png",
    superDogNftCreatorAvatar: avt,
    superDogNftCreatorName: "Nina holland",
    superDogNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/romeo/data/romeo_shepherd.json",
    r_qty: 19,
    bnb: 0.02,
    busd: 5,
    defly: 400,
  },

  //------------------------------------------------6----------------------------------------//
  {
    id: 24,
    name: "JESTER",
    image: "../../Assets/DeflyBall/images/border-simba/jester-p.png",
    superDogNftCreatorAvatar: avt,
    superDogNftCreatorName: "Jade Soow",
    superDogNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/simba/jsons/jester_collie.json",
    r_qty: 25,
    bnb: 0.03,
    busd: 8,
    defly: 500,
  },
  {
    id: 51,
    name: "KUKI",
    image: "../../Assets/DeflyBall/images/border-simba/kuki-p.png",
    superDogNftCreatorAvatar: avt,
    superDogNftCreatorName: "Jack Hager",
    superDogNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/simba/jsons/kuki_collie.json",
    r_qty: 45,
    bnb: 0.05,
    busd: 10,
    defly: 600,
  },
  {
    id: 49,
    name: "MARSHALL",
    image: "../../Assets/DeflyBall/images/border-simba/marshall-p.png",
    superDogNftCreatorAvatar: avt,
    superDogNftCreatorName: "Nina holland",
    superDogNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/simba/jsons/marshall_collie.json",
    r_qty: 34,
    bnb: 0.1,
    busd: 20,
    defly: 1000,
  },
  {
    id: 23,
    name: "SCOOBY",
    image: "../../Assets/DeflyBall/images/border-simba/scooby-p.png",
    superDogNftCreatorAvatar: avt,
    superDogNftCreatorName: "sabrina kate",
    superDogNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/simba/jsons/scobby_collie.json",
    r_qty: 66,
    bnb: 0.07,
    busd: 15,
    defly: 650,
  },
  {
    id: 9,
    name: "SIMBA",
    image: "../../Assets/DeflyBall/images/border-simba/simba-p.png",
    superDogNftCreatorAvatar: avt,
    superDogNftCreatorName: "Nina holland",
    superDogNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/simba/jsons/simba_border.json",
    r_qty: 19,
    bnb: 0.02,
    busd: 5,
    defly: 400,
  },
  {
    id: 50,
    name: "ZUMA",
    image: "../../Assets/DeflyBall/images/border-simba/zuma-p.png",
    superDogNftCreatorAvatar: avt,
    superDogNftCreatorName: "sabrina kate",
    superDogNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/simba/jsons/zuma_collie.json",
    r_qty: 20,
    bnb: 0.08,
    busd: 18,
    defly: 750,
  },
  //------------------------------------------------7-skooty---------------------------------------//
  {
    id: 33,
    name: "BOWIE",
    image: "../../Assets/DeflyBall/images/corgi-skooty/bowie-p.png",
    superDogNftCreatorAvatar: avt,
    superDogNftCreatorName: "Jade Soow",
    superDogNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/skooty/jsons/bowie_corgi.json",
    r_qty: 66,
    bnb: 0.07,
    busd: 15,
    defly: 650,
  },
  {
    id: 12,
    name: "BRUISER",
    image: "../../Assets/DeflyBall/images/corgi-skooty/bruiser-p.png",
    superDogNftCreatorAvatar: avt,
    superDogNftCreatorName: "Jack Hager",
    superDogNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/skooty/jsons/bruiser_corgi.json",
    r_qty: 34,
    bnb: 0.1,
    busd: 20,
    defly: 1000,
  },
  {
    id: 32,
    name: "DACIOUS",
    image: "../../Assets/DeflyBall/images/corgi-skooty/dacious-p.png",
    superDogNftCreatorAvatar: avt,
    superDogNftCreatorName: "Nina holland",
    superDogNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/skooty/jsons/dacious_corgi.json",
    r_qty: 45,
    bnb: 0.05,
    busd: 10,
    defly: 600,
  },
  {
    id: 4,
    name: "SKOOTY",
    image: "../../Assets/DeflyBall/images/corgi-skooty/skooty-p.png",
    superDogNftCreatorAvatar: avt,
    superDogNftCreatorName: "sabrina kate",
    superDogNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/skooty/jsons/skooty_corgi.json",
    r_qty: 19,
    bnb: 0.02,
    busd: 5,
    defly: 400,
  },
  {
    id: 31,
    name: "TODDY",
    image: "../../Assets/DeflyBall/images/corgi-skooty/toddy-p.png",
    superDogNftCreatorAvatar: avt,
    superDogNftCreatorName: "Nina holland",
    superDogNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/skooty/jsons/toddy_corgi.json",
    r_qty: 25,
    bnb: 0.03,
    busd: 8,
    defly: 500,
  },
  {
    id: 11,
    name: "WELSHINE",
    image: "../../Assets/DeflyBall/images/corgi-skooty/welshine-p.png",
    superDogNftCreatorAvatar: avt,
    superDogNftCreatorName: "sabrina kate",
    superDogNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/skooty/jsons/welshine_corgi.json",
    r_qty: 20,
    bnb: 0.08,
    busd: 18,
    defly: 750,
  },
  //------------------------------------------------8----------------------------------------//
  {
    id: 36,
    name: "APPOLLO",
    image:
      "../../Assets/DeflyBall/images/staffordshirebull-spitfire/appollo-p.png",
    superDogNftCreatorAvatar: avt,
    superDogNftCreatorName: "Jade Soow",
    superDogNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/spitfire/jsons/appollo_terrier.json",
    r_qty: 20,
    bnb: 0.08,
    busd:18,
    defly: 750,
  },
  {
    id: 34,
    name: "BEAU",
    image:
      "../../Assets/DeflyBall/images/staffordshirebull-spitfire/beau-p.png",
    superDogNftCreatorAvatar: avt,
    superDogNftCreatorName: "Jack Hager",
    superDogNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/spitfire/jsons/beau_terrier.json",
    r_qty: 45,
    bnb: 0.05,
    busd: 10,
    defly: 600,
  },
  {
    id: 13,
    name: "BUTCH",
    image:
      "../../Assets/DeflyBall/images/staffordshirebull-spitfire/butch-p.png",
    superDogNftCreatorAvatar: avt,
    superDogNftCreatorName: "Nina holland",
    superDogNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/spitfire/jsons/butch_terrier.json",
    r_qty: 66,
    bnb: 0.07,
    busd: 15,
    defly: 650,
  },
  {
    id: 35,
    name: "ENZO",
    image:
      "../../Assets/DeflyBall/images/staffordshirebull-spitfire/enzo-p.png",
    superDogNftCreatorAvatar: avt,
    superDogNftCreatorName: "sabrina kate",
    superDogNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/spitfire/jsons/enzo_terrier.json",
    r_qty: 34,
    bnb: 0.1,
    busd: 20,
    defly: 1000,
  },
  {
    id: 14,
    name: "MURPHEY",
    image:
      "../../Assets/DeflyBall/images/staffordshirebull-spitfire/murphey-p.png",
    superDogNftCreatorAvatar: avt,
    superDogNftCreatorName: "Nina holland",
    superDogNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/spitfire/jsons/murphey_terrier.json",
    r_qty: 25,
    bnb: 0.03,
    busd: 8,
    defly: 500,
  },
  {
    id: 2,
    name: "SPITFIRE",
    image:
      "../../Assets/DeflyBall/images/staffordshirebull-spitfire/spitfire-p.png",
    superDogNftCreatorAvatar: avt,
    superDogNftCreatorName: "sabrina kate",
    superDogNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/spitfire/jsons/spitfire_bull.json",
    r_qty: 19,
    bnb: 0.02,
    busd: 5,
    defly: 400,
  },
  //------------------------------------------------9swifty----------------------------------------//
  // {
  //   id: 44,
  //   name: "BRUTUS",
  //   image: "../../Assets/DeflyBall/images/doberman-swifty/brutus-p.png",
  //   superDogNftCreatorAvatar: avt,
  //   superDogNftCreatorName: "Jade Soow",
  //   superDogNftCreatorPrice: "4.89 ETH",
  //   json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/swifty/jsons/brutus_doberman.json",
  //   r_qty: 34,
  //   bnb: 0.1,
  //   busd: 20,
  //   defly: 1000,
  // },
  // {
  //   id: 45,
  //   name: "ELI",
  //   image: "../../Assets/DeflyBall/images/doberman-swifty/eli-p.png",
  //   superDogNftCreatorAvatar: avt,
  //   superDogNftCreatorName: "Jack Hager",
  //   superDogNftCreatorPrice: "4.89 ETH",
  //   json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/swifty/jsons/eli_doberman.json",
  //   r_qty: 45,
  //   bnb: 0.05,
  //   busd: 10,
  //   defly: 600,
  // },
  // {
  //   id: 43,
  //   name: "FELIX",
  //   image: "../../Assets/DeflyBall/images/doberman-swifty/felix-p.png",
  //   superDogNftCreatorAvatar: avt,
  //   superDogNftCreatorName: "Nina holland",
  //   superDogNftCreatorPrice: "4.89 ETH",
  //   json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/swifty/jsons/felix_doberman.json",
  //   r_qty: 66,
  //   bnb: 0.07,
  //   busd: 15,
  //   defly: 650,
  // },
  // {
  //   id: 19,
  //   name: "SLICKY",
  //   image: "../../Assets/DeflyBall/images/doberman-swifty/slicky-p.png",
  //   superDogNftCreatorAvatar: avt,
  //   superDogNftCreatorName: "sabrina kate",
  //   superDogNftCreatorPrice: "4.89 ETH",
  //   json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/swifty/jsons/slicky_doberman.json",
  //   r_qty: 20,
  //   bnb: 0.08,
  //   busd:18,
  //   defly: 750,
  // },
  // {
  //   id: 5,
  //   name: "SWIFTY",
  //   image: "../../Assets/DeflyBall/images/doberman-swifty/swifty-p.png",
  //   superDogNftCreatorAvatar: avt,
  //   superDogNftCreatorName: "Nina holland",
  //   superDogNftCreatorPrice: "4.89 ETH",
  //   json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/swifty/jsons/swifty_doberman.json",
  //   r_qty: 19,
  //   bnb: 0.02,
  //   busd: 5,
  //   defly: 400,
  // },
  // {
  //   id: 20,
  //   name: "TOBY",
  //   image: "../../Assets/DeflyBall/images/doberman-swifty/toby-p.png",
  //   superDogNftCreatorAvatar: avt,
  //   superDogNftCreatorName: "sabrina kate",
  //   superDogNftCreatorPrice: "4.89 ETH",
  //   json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/swifty/jsons/toby_doberman.json",
  //   r_qty: 25,
  //   bnb: 0.03,
  //   busd: 8,
  //   defly: 500,
  // },
  //------------------------------------------------10--thunder--------------------------------------//
  {
    id: 52,
    name: "LINUS",
    image: "../../Assets/DeflyBall/images/jack-thunder/linus-p.png",
    superDogNftCreatorAvatar: avt,
    superDogNftCreatorName: "Jade Soow",
    superDogNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/thunder/jsons/linus_russel.json",
    r_qty: 25,
    bnb: 0.03,
    busd:8,
    defly: 500,
  },
  {
    id: 54,
    name: "LORAX",
    image: "../../Assets/DeflyBall/images/jack-thunder/lorax-p.png",
    superDogNftCreatorAvatar: avt,
    superDogNftCreatorName: "Jack Hager",
    superDogNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/thunder/jsons/lorax_russel.json",
    r_qty: 45,
    bnb: 0.05,
    busd: 10,
    defly: 600,
  },
  {
    id: 25,
    name: "THOR",
    image: "../../Assets/DeflyBall/images/jack-thunder/thor-p.png",
    superDogNftCreatorAvatar: avt,
    superDogNftCreatorName: "Nina holland",
    superDogNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/thunder/jsons/thor_russel.json",
    r_qty: 34,
    bnb: 0.1,
    busd: 20,
    defly: 1000,
  },
  {
    id: 7,
    name: "THUNDER",
    image: "../../Assets/DeflyBall/images/jack-thunder/thunder-p.png",
    superDogNftCreatorAvatar: avt,
    superDogNftCreatorName: "sabrina kate",
    superDogNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/thunder/jsons/thunder_russell.json",
    r_qty: 19,
    bnb: 0.02,
    busd: 5,
    defly: 400,
  },
  {
    id: 26,
    name: "TITAN",
    image: "../../Assets/DeflyBall/images/jack-thunder/titan-p.png",
    superDogNftCreatorAvatar: avt,
    superDogNftCreatorName: "Nina holland",
    superDogNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/thunder/jsons/titan_russel.json",
    r_qty: 20,
    bnb: 0.08,
    busd: 18,
    defly: 750,
  },
  {
    id: 53,
    name: "ZUKO",
    image: "../../Assets/DeflyBall/images/jack-thunder/zuko-p.png",
    superDogNftCreatorAvatar: avt,
    superDogNftCreatorName: "sabrina kate",
    superDogNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/thunder/jsons/zuko_russel.json",
    r_qty: 66,
    bnb: 0.07,
    busd: 15,
    defly: 650,
  },
];

export const FoodkitData = [
  {
    id: "12",
    foodKitNftName: "Gubbin Kit",
    foodKitNftImage: "../../Assets/DeflyBall/food-kits/GUBBIN_KIT.webp",
    foodKitNftCreatorAvatar: avt,
    foodKitNftCreatorName: "Sonia Williams",
    foodKitNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/food_kits/jsons/gubbin.json",
    r_qty: 13,
    bnb: 0.02,
    busd: 3,
    defly: 300,
  },
  {
    id: "13",
    foodKitNftName: "Jarvis Kit",
    foodKitNftImage: "../../Assets/DeflyBall/food-kits/JARVIS_KIT.webp",
    foodKitNftCreatorAvatar: avt,
    foodKitNftCreatorName: "Sonia Williams",
    foodKitNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/food_kits/jsons/jarvis.json",
    r_qty: 13,
    bnb: 0.02,
    busd: 3,
    defly: 300,
  },
  {
    id: "11",
    foodKitNftName: "Mega  Kit",
    foodKitNftImage: "../../Assets/DeflyBall/food-kits/MEGA_KIT.webp",
    foodKitNftCreatorAvatar: avt,
    foodKitNftCreatorName: "Sonia Williams",
    foodKitNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/food_kits/jsons/mega.json",
    r_qty: 13,
    bnb: 0.02,
    busd: 3,
    defly: 300,
  },
  {
    id: "15",
    foodKitNftName: "Spank  Kit",
    foodKitNftImage: "../../Assets/DeflyBall/food-kits/SPANK_KIT.webp",
    foodKitNftCreatorAvatar: avt,
    foodKitNftCreatorName: "Sonia Williams",
    foodKitNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/food_kits/jsons/spank.json",
    r_qty: 13,
    bnb: 0.02,
    busd: 3,
    defly: 300,
  },
  {
    id: "14",
    foodKitNftName: "Treatos Kit",
    foodKitNftImage: "../../Assets/DeflyBall/food-kits/TREATOS_KIT.webp",
    foodKitNftCreatorAvatar: avt,
    foodKitNftCreatorName: "Sonia Williams",
    foodKitNftCreatorPrice: "4.89 ETH",
    json: "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/food_kits/jsons/treatos.json",
    r_qty: 13,
    bnb: 0.02,
    busd: 3,
    defly: 300,
  },
];
