import React from "react";
import "./BuyNft.css";
import Navbar from "../Components/Navbar/Navbar";
import NftDetail from "../Components/nftDetail/NftDetail";
import Footer from "../Components/Footer/Footer";
import { useEffect } from "react";
function BuyNft() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div>
      <section className="buy-nft">
        <Navbar />
        <NftDetail />
        <Footer />
      </section>
    </div>
  );
}

export default BuyNft;
