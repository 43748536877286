import "./App.css";
import MyNFT from "./Components/Mynft/MyNFT";
import BuyNft from "./Page/BuyNft";
import MarketBuyNft from "./Page/MarketBuyNft";
import LandingPage from "./Page/LandingPage";
import { Routes, Route } from "react-router-dom";
import Mysterybox from "./Components/MysteryBox/Mysterybox";
import MysteryLand from "./Page/MysteryLand";
import { ToastContainer } from "react-toastify";
import FoodkitDetails from "./Components/nftDetailN/foodkitdetails";
import Foodnftdetails from "./Components/foodnftdetails";
// import MarketNftDetail from "./Components/nftDetail/marketNFTDetail";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/market" element={<LandingPage />} />
        <Route path="/buynft/:id" element={<BuyNft />} />
        {/* <Route path="/marketnftdetail:id" element={<MarketBuyNft />} /> */}
        <Route path="/assets-nft/:id" element={<MarketBuyNft />} />
        <Route path="/foodkits/:id" element={<Foodnftdetails />} />
        <Route path="/mynft" element={<MyNFT />} />
        <Route path="/mysteryBox" element={<Mysterybox />} />
        {/* <Route path="/conectWallet" element={<ConnectWallet />} /> */}
        {/* <Route path="/" element={<MysteryLand />} /> */}
        <Route path="/" element={<LandingPage />} />
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
