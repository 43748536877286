import React from "react";
import Navbar from "../Navbar/Navbar";
import "./Hero.css";
import HeroCard1 from "../../Assets/Images/HeroCard3.png";
import Countdown from "react-countdown";

function Hero() {
  const customRender = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
    } else {
      // Render a countdown
      return (
        <div className="custom-countdown">
          {/* <span className="days">{days}</span>
          <span className="colon">:</span>
          <span className="days">{hours}</span>
          <span className="colon">:</span>
          <span className="days">{minutes}</span>
          <span className="colon">:</span>
          <span className="days">{seconds}</span> */}
          <span className="colon">LIVE NOW</span>
        </div>
      );
    }
  };
  return (
    <div>
      <section className="Hero">
        <Navbar />
        <div className="container">
          <div className="Hero-Content ">
            <div className="row content-top">
              <div className="col-lg-5 col-12 ">
                <div className="HeroSlogans">
                  <h1>DEFLY BALL</h1>
                  <h2>MARKETPLACE</h2>
                </div>
              </div>
              <div className="col-lg-7 col-12">
                <div className="HeroPhotos ">
                  <div className="HeroPhotos-set">
                    <img
                      src={HeroCard1}
                      alt=""
                      className=" img-fluid HeroCard1"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row content-bottom ">
              <div className="col-12 hero-var ">
                <span className="Hero-variations ">
                  <Countdown
                    date={new Date(parseInt(1666288800) * 1000)}
                    renderer={customRender}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Hero;
