import React from "react";
import Navbar from "../Components/Navbar/Navbar";
import Footer from "../Components/Footer/Footer";
import { useEffect } from "react";

import FoodkitDetails from "./nftDetailN/foodkitdetails";

function Foodnftdetails() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div>
      <section className="buy-nft">
        <Navbar />
        <FoodkitDetails />
        <Footer />
      </section>
    </div>
  );
}

export default Foodnftdetails;
