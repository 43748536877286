import React from "react";
import "./MyNftHero.css";
import Navbar from "../Navbar/Navbar";

function MyNftHero() {
  return (
    <div>
      <section className="my-nft-hero">
        <Navbar />
        <div className="container h-75 d-flex justify-content-center flex-column">
          <div className="row">
            <div className="col-md-6 col-12 ">
              <div className="my-nft-hero-slogan">
                <h1>MY NFT’S</h1>
                <h2>MY DOGS</h2>
                <h3>COLLECTION</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default MyNftHero;
