import React from "react";
import "./MarketProductCard.css";
import etherLogo from "../../Assets/Images/binance-coin-seeklogo.com.svg";
import favicon from "../../Assets/Images/fav-icon.svg";
import defly_logo from "../../Assets/Images/Logo.png";
import { Link } from "react-router-dom";
function MarketPlaceProductCustomCard({ cardData }) {
  
  return (
    <Link to={"/assets-nft"} params={{ cardData }}>
      <div>
        <div className="Market-product-cards">
          <div className="favourite  d-flex">
            <p className="">
              <img src={favicon} alt="" /> 100
            </p>
          </div>
          <div className="card-top">
            <div className="card-img">
              <img src={cardData.image} alt="" style={{ width: "200%" }} />
            </div>
          </div>
          <div className="card-center">
            <div className="nft-name itemCustomName">{cardData.name}</div>
          </div>

          <div className="card-bottom">
            <div className="nft-detail">
              <div className="nft-creator">
                <img src={defly_logo} alt="" className="me-1 customAvatarImg" />

                <div className="nft-creator-detail">
                  <h6 className="nft-head">Creator</h6>
                  <h5 className="nft-creator-name">{cardData.name}</h5>
                </div>
              </div>
              <div className="nft-price">
                <h6 className="nft-head">Price</h6>
                <div className="price ">
                  <img src={etherLogo} alt="" />
                  <h5 className="nft-price-tag">N/A</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default MarketPlaceProductCustomCard;
