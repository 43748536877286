import React, { useEffect, useState } from "react";
import { superDogNFT } from "../Data.js";
import ReactPaginate from "react-paginate";
import "./MarketProductCard.css";
import etherLogo from "../../Assets/Images/binance-coin-seeklogo.com.svg";
import favicon from "../../Assets/Images/fav-icon.svg";
import defly_logo from "../../Assets/Images/Logo.png";
import { Link } from "react-router-dom";
const superDogNFTSortedById = [...superDogNFT].sort((a, b) => a.id - b.id);
 
function Items({ currentItems }) {
  return (
    <>
      <div className="row">
        {currentItems.map((item, index) => {
          return (
            <div
              className="col-xl-3 col-lg-4 col-md-6 col-12 d-flex justify-content-center mb-5"
              data-aos="flip-left"
              key={index}
            >
              <Link
                to={`/assets-nft/${item.newjson[7]}-${item.newjson[8]}-${item.newjson[9]}`}
                params={{ currentItems }}
              >
                <div>
                  <div className="Market-product-cards">
                    {/* <div className="favourite  d-flex">
                      <p className="">
                        <img src={favicon} alt="" /> 100
                      </p>
                    </div> */}
                    <div className="card-top">
                      <div className="card-img">
                        <img
                          src={item.image}
                          alt=""
                          style={{ width: "200%" }}
                        />
                      </div>
                    </div>
                    <div className="card-center">
                      <div className="nft-name itemCustomName">{item.name}</div>
                    </div>

                    <div className="card-bottom">
                      <div className="nft-detail">
                        <div className="nft-creator">
                          <img
                            src={defly_logo}
                            alt=""
                            className="me-1 customAvatarImg"
                          />

                          <div className="nft-creator-detail">
                            <h6 className="nft-head">Creator</h6>
                            <h5 className="nft-creator-name">{item.name}</h5>
                          </div>
                        </div>
                        <div className="nft-price">
                          <h6 className="nft-head">Price</h6>
                          <div className="price ">
                            <img src={etherLogo} alt="" />
                            <h5 className="nft-price-tag">{item.bnb}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    </>
  );
}

function MarketTabProducts() {
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 12;

  useEffect(() => {
    // Fetch items from another resources.

    if (superDogNFTSortedById) {
      for (var i = 0; i < superDogNFTSortedById.length; i++) {
        superDogNFTSortedById[i].newjson =
          superDogNFTSortedById[i].json.split(/[/.]+/);
      }
    }

    const endOffset = itemOffset + itemsPerPage;

    setCurrentItems(superDogNFTSortedById.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(superDogNFTSortedById.length / itemsPerPage));
    // https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/bella/jsons
  }, [itemOffset, itemsPerPage]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % superDogNFTSortedById.length;

    setItemOffset(newOffset);
  };

  return (
    <div>
      {currentItems && currentItems.length > 0 ? (
        <Items currentItems={currentItems} />
      ) : (
        <></>
      )}
      <div className="pagination">
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
        />
      </div>
    </div>
  );
}

export default MarketTabProducts;
