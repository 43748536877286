import React, { useEffect, useState } from "react";
import "./NftDetail.css";
import nftDetailHead from "../../Assets/Images/nftDetailPage/nftDetailHead.png";
import otherNftHead from "../../Assets/Images/nftDetailPage/otherNftHead.png";
import nftThumbnail from "../../Assets/Images/nftDetailPage/nftThumbnail.png";
import Navbar from "../Navbar/Navbar";
import etherLogo from "../../Assets/Images/binance-coin-seeklogo.com.svg";

import { superDogNFT } from "../Data.js";
import MarketProductCard from "../Market/MarketProductCard";
import MarketPlaceProductCustomCard from "../Market/MarketPlaceProductCustomCard";
import { useLocation } from "react-router-dom";
import { getNFTsDetail, getNFTsDetailNew } from "../../utils/interact";
// import ClipLoader from "react-spinners/ClipLoader";
import Spinner from "react-bootstrap/Spinner";

const NftDetail = (props) => {
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("#ffffff");

  const location = useLocation();
  const [myDogsNfts, setmyDogsNfts] = useState([]);

  // const result = location.pathname.split(/[.\-&=/_]/);
  const getNFTsDetailFromContract = async () => {
    const result = location.pathname.split(/[.\-&=/_]/);
    console.log(result);
    let nftDetails = null;
    if (result[3] && result[3] == "new") {
      nftDetails = await getNFTsDetailNew(result[2]);
    } else {
      nftDetails = await getNFTsDetail(result[2]);
    }
    // let nftDetails = await getNFTsDetail(result[2]);
    setmyDogsNfts(nftDetails.nftData[0]);
  };

  useEffect(() => {
    getNFTsDetailFromContract();
  }, []);
  // useEffect(() => {
  //   if (myDogsNfts && myDogsNfts !== []) {
  //     setLoading(false);
  //   }
  // }, [myDogsNfts]);
  const toggleLoading = async () => {
    setLoading(false);
  };
  return (
    <div>
      {loading ? (
        <Spinner
          className="loader"
          animation="border"
          role="status"
          variant="warning"
        >
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : (
        <></>
      )}
      <section className="NftDetail">
        <div className="container">
          <div className="NftDetail-heading ">
            <img src={nftDetailHead} alt="" />
          </div>
          <div className="nftProduct">
            <div className="row p-5">
              <div className="col-md-5 col-12 nftThumbnail ">
                {/* <img src={myDogsNfts.image} alt="" className="img-fluid" /> */}
                {/* <img src={nftThumbnail} alt="" className="img-fluid" /> */}

                <img
                  onLoad={toggleLoading}
                  src={
                    myDogsNfts && myDogsNfts.image_1 ? myDogsNfts.image_1 : ""
                  }
                  alt=""
                  className="dogImg"
                />
              </div>

              <div className="col-md-7 col-12 nft-details">
                <div className="nft-name">
                  <h1>
                    {myDogsNfts && myDogsNfts.name ? myDogsNfts.name : ""}
                  </h1>
                  {/* <a className="buy-nft-button">BUY</a> */}
                </div>
                <div className="discription">
                  {/* <div className="nft-price">
                    <h3>Price</h3>
                    <div className="price ">
                      <img src={etherLogo} alt="" />
                      <h5 className="nft-price-tag">N/A</h5>
                    </div>
                  </div> */}

                  <div className="nft-discription pt-2">
                    <div className="nft-discription-head">
                      <h5>Description</h5>
                    </div>
                    <div className="nft-discription-para">
                      <p>
                        {myDogsNfts && myDogsNfts.description
                          ? myDogsNfts.description
                          : ""}
                      </p>
                    </div>
                  </div>

                  <div className="nft-discription-detail pt-2">
                    <div className="nft-discription-detail-head">
                      <h5>Details</h5>
                    </div>
                    <div className="nft-features mt-3 ">
                      <div className="nft-features-head ">
                        <h3>Attributes</h3>
                        <h3>Level</h3>
                      </div>
                      <div className="feature-detail mt-3">
                        <div className="feature-name">
                          <h4>Speed</h4>
                        </div>
                        <div className="feature-progress">
                          <div className="mybar100">
                            <div
                              className="myBar"
                              style={{
                                width:
                                  myDogsNfts && myDogsNfts.attributes
                                    ? myDogsNfts.attributes[0].value + "%"
                                    : "0%",
                              }}
                            ></div>
                          </div>
                          <div className="prog">
                            <p>
                              {myDogsNfts && myDogsNfts.attributes ? (
                                myDogsNfts.attributes[0].value
                              ) : (
                                <></>
                              )}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="feature-detail mt-3">
                        <div className="feature-name">
                          <h4>Agility</h4>
                        </div>
                        <div className="feature-progress">
                          <div className="mybar100">
                            <div
                              className="myBar"
                              style={{
                                width:
                                  myDogsNfts && myDogsNfts.attributes
                                    ? myDogsNfts.attributes[1].value + "%"
                                    : "0%",
                              }}
                            ></div>
                          </div>
                          <div className="prog">
                            <p>
                              {myDogsNfts && myDogsNfts.attributes ? (
                                myDogsNfts.attributes[1].value
                              ) : (
                                <></>
                              )}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="feature-detail mt-3">
                        <div className="feature-name">
                          <h4>Stamina</h4>
                        </div>
                        <div className="feature-progress">
                          <div className="mybar100">
                            <div
                              className="myBar"
                              style={{
                                width:
                                  myDogsNfts && myDogsNfts.attributes
                                    ? myDogsNfts.attributes[2].value + "%"
                                    : "0%",
                              }}
                            ></div>
                          </div>
                          <div className="prog">
                            <p>
                              {myDogsNfts && myDogsNfts.attributes ? (
                                myDogsNfts.attributes[2].value
                              ) : (
                                <></>
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br></br>
          {/* <div className="NftDetail-heading ">
            <img src={otherNftHead} alt="" />
          </div>

          <div className="nft-other">
            <div className="row">
              {superDogNFT.map((item) => {
                return (
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 d-flex justify-content-center mb-5">
                    <MarketPlaceProductCustomCard cardData={item} />
                  </div>
                );
              })}
            </div>
          </div> */}
        </div>
      </section>
    </div>
  );
};

export default NftDetail;
