import React from "react";
import "./marketNFTDetail.css";
import nftDetailHead from "../../Assets/Images/nftDetailPage/nftDetailHead.png";
import otherNftHead from "../../Assets/Images/nftDetailPage/otherNftHead.png";
import nftThumbnail from "../../Assets/Images/nftDetailPage/nftThumbnail.png";
import Navbar from "../Navbar/Navbar";
import bnblogo from "../../Assets/Images/binance-coin-seeklogo.com.svg";
import busdLogo from "../../Assets/Images/busd.svg";
import defly_logo from "../../Assets/Images/Logo.png";
import { FoodkitData } from "../Data.js";
import MarketProductCard from "../Market/MarketProductCard";
import MarketPlaceProductCustomCard from "../Market/MarketPlaceProductCustomCard";
import favicon from "../../Assets/Images/fav-icon.svg";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { checkAllowance, onApprove, onBuyFood } from "../../utils/interact";
import { Spinner } from "react-bootstrap";
import { collection, getDocs, query, where } from "firebase/firestore";
import { firestoredb } from "../../firebase";

function FoodkitDetails() {
  //Loading
  const [isLoading, setisLoading] = useState(false);
  //Main Net
  const bnbChainId = "0x38";
  //Test Net
  // const bnbChainId = "0x61";

  const [curr, setCurr] = useState("1"); //Set Currency 1-BNB, 2-BUSD, 3-DEFLY

  //Approve Button
  const [isApprove, setIsApprove] = useState(false);
  const [isBuy, setIsBuy] = useState(false);

  const [jsonLink, setJsonlink] = useState("");
  const [Othernfts, setOthers] = useState("");

  const [nftDetails, setDetails] = useState([]);
  const [nftPrice, setNftPrice] = useState(""); //Dog NFT Price in BNB, BUSD or DEFLY
  const [totalNFTsMinted, setTotalNFTsMinted] = useState(0);
  const [isQuantityFull, setIsQuantityFull] = useState(true);

  const location = useLocation();
  useEffect(() => {
    var temp1 = location.pathname.replace("/foodkits/", "");
    var temp2 = temp1.replaceAll("-", "/");
    // var temp3 = temp2.replaceAll("1997", ".");
    // setTemp33(temp3);
    var jsonLinks =
      "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/food_kits/jsons/" +
      temp2 +
      ".json";

    setJsonlink(jsonLinks);

    var test = jsonLinks.split("/");

    setOthers(test[4]);
  }, []);
  const getNFTQuantityfromFirestore = async (nftDetailTemp) => {
    const q = query(
      collection(firestoredb, "mintedFoods"),
      where("id", "==", nftDetailTemp.id)
    );

    const querySnapshot = await getDocs(q);

    setTotalNFTsMinted(querySnapshot.size + nftDetailTemp.r_qty);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
    });
  };

  const getData = async () => {
    const response = await fetch(jsonLink)
      .then((response) => response.json())
      .then(async (json) => {
        const result = await FoodkitData.find(({ id }) => id == json.id);

        var nftdetailsdata = {
          id: json.id,
          image_1: json.image,
          name: json.name,
          description: json.description,
          attributes: json.attributes,
          r_qty: result.r_qty,
          bnb: result.bnb,
          busd: result.busd,
          defly: result.defly,
        };

        setDetails(nftdetailsdata);
        setNftPrice(result.busd);
      });
  };

  useEffect(() => {
    if (jsonLink && jsonLink != "") {
      getData();
    }
  }, [jsonLink]);

  const checkUserAllowance = async () => {
    const allowanceRes = await checkAllowance(curr, nftPrice.toString());

    if (allowanceRes) {
      setIsApprove(false);
      setIsBuy(true);
    } else {
      setIsBuy(false);
      setIsApprove(true);
    }
    setisLoading(false);
    if (totalNFTsMinted >= 200) {
      setIsApprove(false);
      setIsBuy(false);
      setIsQuantityFull(false);
    }
  };
  useEffect(() => {
    if (curr == 1) {
      //On BUSD
      setIsBuy(false);
      setIsApprove(true);
      setNftPrice(nftDetails.busd);
      setisLoading(true);
      checkUserAllowance();
    } else if (curr == 2) {
      //On DEFLY
      setIsBuy(false);
      setIsApprove(true);
      setNftPrice(nftDetails.defly);
      setisLoading(true);
      checkUserAllowance();
    } else if (curr == 3) {
      //On BNB
      setIsApprove(false);
      setIsBuy(true);
      setNftPrice(nftDetails.bnb);
    }
    if (totalNFTsMinted >= 200) {
      setIsApprove(false);
      setIsBuy(false);
      setIsQuantityFull(false);
    }
  }, [curr, nftPrice]);
  const onChangeRadioValue = (event) => {
    setCurr(event.target.value);
  };

  //On Approve Button Click
  const onApprovePressed = async () => {
    if (window.ethereum.chainId === bnbChainId) {
      if (isLoading) {
        toast.warning("Please wait!", {
          toastId: "pleaseWait",
        });
      } else {
        setisLoading(true);
        const approveRes = await onApprove(curr, nftPrice.toString());

        // setisLoading(false);
        // checkUserAllowance();
      }
    } else {
      toast.error("Please connect to BSC main network", {
        toastId: "error1",
      });
    }
  };
  //On Buy Button Click
  const onBuyPressed = async () => {
    if (window.ethereum.chainId === bnbChainId) {
      if (totalNFTsMinted < 200) {
        if (isLoading) {
          toast.warning("Please wait!", {
            toastId: "pleaseWait",
          });
        } else {
          setisLoading(true);
          const buyRes = await onBuyFood(
            nftDetails.id,
            jsonLink,
            nftPrice,
            curr
          );

          setisLoading(false);
        }
      } else {
        toast.warning("No Stock!", {
          toastId: "quantityFull",
        });
      }
    } else {
      toast.error("Please connect to BSC main network", {
        toastId: "error1",
      });
    }
  };
  useEffect(() => {
    if (nftDetails.id) {
      getNFTQuantityfromFirestore(nftDetails);
    }
  }, [nftDetails]);
  useEffect(() => {
    if (totalNFTsMinted >= 200) {
      setIsApprove(false);
      setIsBuy(false);
      setIsQuantityFull(false);
    }
  }, [totalNFTsMinted]);

  const [logo, setLogo] = useState(busdLogo);

  const setBusdLogo = () => {
    setLogo(busdLogo);
  };
  const setBnbLogo = () => {
    setLogo(bnblogo);
  };
  const setDeflyLogo = () => {
    setLogo(defly_logo);
  };
  return (
    <div>
      <section className="NftDetail">
        <div className="container">
          <div className="NftDetail-heading ">
            <img src={nftDetailHead} alt="" />
          </div>
          <div className="nftProduct food-kit">
            <div className="row p-5">
              <div className="col-md-5 col-12 nftThumbnail ">
                <div className="nft-details-img-food">
                  <img
                    src={nftDetails.image_1}
                    alt=""
                    className="main-image-food-kit"
                  />
                </div>
              </div>
              {isLoading && (
                <>
                  <div className="loaderSecond">
                    <Spinner
                      animation="border"
                      style={{
                        width: "60px",
                        height: "60px",
                        borderRightColor: "#FCC016",
                      }}
                    />
                    <span className="customLoadingText">LOADING...</span>
                  </div>
                </>
              )}
              <div className="col-md-7 col-12 nft-details">
                <div className="nft-name">
                  <h1>{nftDetails.name}</h1>
                  <a className="quantity"> QUANTITY {totalNFTsMinted}/200</a>
                </div>
                <div className="discription">
                  <div className="nft-price">
                    <h3>Price</h3>
                    <div className="price ">
                      <img src={logo} alt="" />
                      <h5 className="nft-price-tag">{nftPrice}</h5>
                    </div>
                  </div>
                  {isQuantityFull ? (
                    <div className="wrapp-outer">
                      <div
                        className="wrapperMarketDetail"
                        onChange={onChangeRadioValue}
                      >
                        <input
                          type="radio"
                          value="1"
                          name="select"
                          id="option-1"
                          defaultChecked
                        />
                        <input
                          type="radio"
                          value="2"
                          name="select"
                          id="option-2"
                        />
                        <input
                          type="radio"
                          value="3"
                          name="select"
                          id="option-3"
                        />
                        <label
                          htmlFor="option-1"
                          className="option option-1"
                          onClick={setBusdLogo}
                        >
                          <div className="dot"></div>
                          <span style={{ color: "white", padding: "inherit" }}>
                            BUSD
                          </span>
                        </label>
                        <label
                          htmlFor="option-2"
                          className="option option-2"
                          onClick={setDeflyLogo}
                        >
                          <div className="dot"></div>
                          <span style={{ color: "white", padding: "inherit" }}>
                            DEFLY
                          </span>
                        </label>
                        <label
                          htmlFor="option-3"
                          className="option option-3"
                          onClick={setBnbLogo}
                        >
                          <div className="dot"></div>
                          <span style={{ color: "white", padding: "inherit" }}>
                            BNB
                          </span>
                        </label>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="wrapp-outer p-0 approveBuyBtn">
                    {isApprove ? (
                      <button
                        className="buy-nft-button"
                        onClick={onApprovePressed}
                      >
                        APPROVE
                      </button>
                    ) : (
                      <></>
                    )}
                    {isBuy ? (
                      <button className="buy-nft-button" onClick={onBuyPressed}>
                        BUY
                      </button>
                    ) : (
                      <></>
                    )}
                    {!isQuantityFull ? (
                      <button className="buy-nft-button">SOLD OUT</button>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="nft-discription pt-2">
                    <div className="nft-discription-head">
                      <h5>Description</h5>
                    </div>
                    <div className="nft-discription-para">
                      <p>{nftDetails.description}</p>
                    </div>
                  </div>

                  {/* <div className="nft-discription-detail pt-2">
                    <div className="nft-discription-detail-head">
                      <h5>Details</h5>
                    </div>
                    <div className="nft-features mt-3 ">
                      <div className="nft-features-head ">
                        <h3>Attributes</h3>
                        <h3>Level</h3>
                      </div>
                      <div className="feature-detail mt-3">
                        <div className="feature-name">
                          <h4>Speed</h4>
                        </div>
                        <div className="feature-progress">
                          <div className="mybar100">
                            <div
                              className="myBar"
                              style={{
                                width:
                                  nftDetails && nftDetails.attributes
                                    ? nftDetails.attributes[0].value + "%"
                                    : "0%",
                              }}
                            ></div>
                          </div>
                          <div className="prog">
                            <p>
                              {nftDetails && nftDetails.attributes ? (
                                nftDetails.attributes[0].value
                              ) : (
                                <></>
                              )}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="feature-detail mt-3">
                        <div className="feature-name">
                          <h4>Agility</h4>
                        </div>
                        <div className="feature-progress">
                          <div className="mybar100">
                            <div
                              className="myBar"
                              style={{
                                width:
                                  nftDetails && nftDetails.attributes
                                    ? nftDetails.attributes[1].value + "%"
                                    : "0%",
                              }}
                            ></div>
                          </div>
                          <div className="prog">
                            <p>
                              {nftDetails && nftDetails.attributes ? (
                                nftDetails.attributes[1].value
                              ) : (
                                <></>
                              )}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="feature-detail mt-3">
                        <div className="feature-name">
                          <h4>Stamina</h4>
                        </div>
                        <div className="feature-progress">
                          <div className="mybar100">
                            <div
                              className="myBar"
                              style={{
                                width:
                                  nftDetails && nftDetails.attributes
                                    ? nftDetails.attributes[2].value + "%"
                                    : "0%",
                              }}
                            ></div>
                          </div>
                          <div className="prog">
                            <p>
                              {nftDetails && nftDetails.attributes ? (
                                nftDetails.attributes[2].value
                              ) : (
                                <></>
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          {/* <div className="NftDetail-heading ">
            <img src={otherNftHead} alt="" />
          </div>

          <div className="nft-other">
            <div className="row">
              {othersuperNFTs.map((item) => {
                return (
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 d-flex justify-content-center mb-5">
                    <Link to={`/marketnftdetail/${item.json}`} target="_blank">
                      <div>
                        <div className="Market-product-cards">
                          <div className="favourite  d-flex">
                            <p className="">
                              <img src={favicon} alt="" /> 100
                            </p>
                          </div>
                          <div className="card-top">
                            <div className="card-img">
                              <img
                                src={item.image}
                                alt=""
                                style={{ width: "200%" }}
                              />
                            </div>
                          </div>
                          <div className="card-center">
                            <div className="nft-name itemCustomName">
                              {item.name}
                            </div>
                          </div>

                          <div className="card-bottom">
                            <div className="nft-detail">
                              <div className="nft-creator">
                                <img
                                  src={defly_logo}
                                  alt=""
                                  className="me-1 customAvatarImg"
                                />

                                <div className="nft-creator-detail">
                                  <h6 className="nft-head">Creator</h6>
                                  <h5 className="nft-creator-name">
                                    {item.name}
                                  </h5>
                                </div>
                              </div>
                              <div className="nft-price">
                                <h6 className="nft-head">Price</h6>
                                <div className="price ">
                                  <img src={etherLogo} alt="" />
                                  <h5 className="nft-price-tag">N/A</h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div> */}
        </div>
      </section>
    </div>
  );
}

export default FoodkitDetails;
