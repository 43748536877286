import MarketFoodKitCard from "./MarketFoodKitCard";
import React from "react";
import foodkitNft from "../../Assets/Images/foodkitNft.png";
import creatorAvatar from "../../Assets/Images/creatorAvatar.png";
import etherLogo from "../../Assets/Images/binance-coin-seeklogo.com.svg";
import defly_logo from "../../Assets/Images/Logo.png";
import favicon from "../../Assets/Images/fav-icon.svg";
import { FoodkitData } from "../Data";
import { Link } from "react-router-dom";

function MarketTabFoodProduct() {
  if (FoodkitData) {
    for (var i = 0; i < FoodkitData.length; i++) {
      FoodkitData[i].json = FoodkitData[i].json.replace(
        "https://deflydogs.s3.us-east-2.amazonaws.com/d3o6g4s8/food_kits/jsons/",
        ""
      );
      FoodkitData[i].json = FoodkitData[i].json.replaceAll("/", "-");
      // superDogNFT[i].json = superDogNFT[i].json.slice(0, -5);
      FoodkitData[i].json = FoodkitData[i].json.replaceAll(".json", "");

      // console.log(superDogNFT[i].json);
    }
  }

  return (
    <div>
      <div className="row">
        {FoodkitData && FoodkitData.length > 0 ? (
          FoodkitData.map((item, index) => {
            return (
              <div
                className="col-xl-3 col-lg-4 col-md-6 col-12 d-flex  justify-content-center mb-md-4 mb-3"
                data-aos="flip-left"
                key={index}
              >
                <Link to={`/foodkits/${item.json}`}>
                  <div className="Market-product-cards food-kit-single">
                    {/* <div className="favourite  d-flex">
                      <p className="">
                        <img src={favicon} alt="" /> 100
                      </p>
                    </div> */}
                    <div className="card-top">
                      <div className="card-img">
                        <img src={item.foodKitNftImage} alt="" />
                      </div>
                    </div>
                    <div className="card-center">
                      <div className="nft-name">
                        <a>{item.foodKitNftName}</a>
                      </div>
                    </div>

                    <div className="card-bottom">
                      <div className="nft-detail">
                        <div className="nft-creator">
                          <img
                            src={defly_logo}
                            alt=""
                            className="me-1 customAvatarImg"
                          />
                          <div className="nft-creator-detail">
                            <h6 className="nft-head">Creator</h6>
                            <h5 className="nft-creator-name">
                              <a>{item.foodKitNftName}</a>
                            </h5>
                          </div>
                        </div>
                        <div className="nft-price">
                          <h6 className="nft-head">Price</h6>
                          <div className="price ">
                            <img src={etherLogo} alt="" />
                            <h5 className="nft-price-tag">{item.bnb}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default MarketTabFoodProduct;
