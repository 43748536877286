// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useHistory } from "react-router-dom";
import { getStorage } from "firebase/storage";
import { useEffect, useState } from "react";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { updateProfile } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { ToastContainer, toast } from "react-toastify";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyCCGKqXbzvybbqalYiQ21LcAr2Mn2Dwq_E",
  authDomain: "defly-ball.firebaseapp.com",
  databaseURL: "https://defly-ball-default-rtdb.firebaseio.com",
  projectId: "defly-ball",
  storageBucket: "defly-ball.appspot.com",
  messagingSenderId: "197476053584",
  appId: "1:197476053584:web:5358c510c07cd71a309755",
  measurementId: "G-7T3877L34V",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage(app);
const db = getDatabase(app);
const firestoredb = getFirestore(app);
// const currentUsera = "";

 
export const useAuth = () => {
  const [currentUser, setCurrentUser] = useState();
  useEffect(() => {
    const unsub = onAuthStateChanged(auth, (user) => setCurrentUser(user));
    return unsub;
  }, []);
  return currentUser;
};

export { auth, app, storage, db, firestoredb };
