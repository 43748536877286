import React, { useEffect, useState } from "react";
import "./MarketProductCard.css";

import etherLogo from "../../Assets/Images/binance-coin-seeklogo.com.svg";
import favicon from "../../Assets/Images/fav-icon.svg";
import defly_logo from "../../Assets/Images/Logo.png";
import yellowbackground from "../../Assets/Images/yellowbackground.jpg";
import Group151 from "../../Assets/Images/Group151.png";

import { Link } from "react-router-dom";
function MarketProductCard({ cardData }) {
  const [linkToSet, setLinkTo] = useState("/buynft/" + cardData.tokenId);
  useEffect(() => {
    if (cardData.newContract == true) {
      setLinkTo("/buynft/" + cardData.tokenId + "-new");
    } else {
      setLinkTo("/buynft/" + cardData.tokenId);
    }
  });
  return (
    <Link to={linkToSet} params={{ cardData }}>
      <div>
        <div className="Market-product-cards">
          {/* <div className="favourite  d-flex">
            <p className="" style={{ zIndex: "999" }}>
              <img src={favicon} alt="" /> 100
            </p>
          </div> */}
          <div className="card-top">
            <div className="card-img">
              {/* <div
                style={{
                  position: "absolute",
                  backgroundImage:
                    "url('https://media.geeksforgeeks.org/wp-content/uploads/rk.png')",
                  height: "100vh",
                  marginTop: "-70px",
                  fontSize: "50px",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
              ></div> */}
              <img
                src={Group151}
                alt=""
                style={{ width: "100%", position: "absolute" }}
              />
              <img
                src={cardData.image}
                alt=""
                style={{ width: "200%", zIndex: "999", bottom: "32%" }}
              />
            </div>
          </div>
          <div className="card-center">
            <div className="nft-name itemCustomName">{cardData.name}</div>
          </div>

          <div className="card-bottom">
            <div className="nft-detail">
              <div className="nft-creator">
                <img src={defly_logo} alt="" className="me-1 customAvatarImg" />

                <div className="nft-creator-detail">
                  <h6 className="nft-head">Creator</h6>
                  <h5 className="nft-creator-name">{cardData.name}</h5>
                </div>
              </div>
              {/* <div className="nft-price">
                <h6 className="nft-head">Price</h6>
                <div className="price ">
                  <img src={etherLogo} alt="" />
                  <h5 className="nft-price-tag">N/A</h5>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default MarketProductCard;
