import React from "react";
import "./Footer.css";
import Logo from "../../Assets/Images/Logo.png";
import subscribearrow from "../../Assets/Images/subscribearrow.png";
import twitterIcon from "../../Assets/Images/twitter-icon.png";
import instagramIcon from "../../Assets/Images/instagram-icon.png";
import facebookIcon from "../../Assets/Images/facebook-icon.png";
import mediumIcon from "../../Assets/Images/discord-icon.svg";
import MarketProductCard from "../Market/MarketProductCard";
import { Icon } from "@iconify/react";
function Footer() {
  return (
    <div>
      <section className="Footer">
        <div className="container">
          <div className="Footer-content">
            <div className="row ">
              <div className="col-lg-2 col-12 d-flex align-items-center justify-content-center mb-lg-0 mb-md-5 mb-5">
                <div className="footer-logo ">
                  <a className="" href="#">
                    <img src={Logo} alt="" />
                    <h6 style={{ color: "#ffffff" }}>
                      DEFLY BALL OÜ (registry code 16312434)
                    </h6>
                  </a>
                </div>
              </div>

              <div className="col-lg-7 col-12 ">
                <div className="row">
                  {/* <div className="col-lg-4 col-md-6 col-12 mb-lg-0 mb-md-0 mb-5">
                    <div className="learn-more">
                      <div className="footer-head">
                        <h6>LEARN MORE</h6>
                      </div>
                      <a href="#">Home</a>
                      <a href="#">Gameplay</a>
                      <a href="#">Roadmap</a>
                      <a href="#">Superverse</a>
                    </div>
                  </div> */}

                  <div className="col-md-4  col-12 mb-lg-0 mb-5">
                    <div className="footer-market">
                      <div className="footer-head">
                        <h6>Marketplace</h6>
                      </div>
                      <a href="/">Dogs</a>

                      <a href="#">Characters</a>
                      <a href="#">In-Game Assets</a>
                      <a href="#">Buy/Sell</a>
                      <a href="https://stake.deflyball.com/" target="s">
                        NFT Staking{" "}
                      </a>
                    </div>
                  </div>

                  {/* <div className="col-md-4  col-12  mb-lg-0 mb-5">
                    <div className="quick-links">
                      <div className="footer-head">
                        <h6>Quick Links</h6>
                      </div>
                      <a href="#">Whitepaper</a>
                      <a href="#">Pitch Deck</a>
                      <a href="#">Marketplace</a>
                      <a href="#">Smart contract</a>
                    </div>
                  </div> */}
                </div>
              </div>

              <div className="col-lg-3 col-md-12  col-12">
                <div className="subscribe text-end">
                  <div className="footer-head">
                    <h6>Subscribe Us</h6>
                  </div>
                  <div className="input-wrap">
                    <input
                      style={{ color: "#ffffff" }}
                      type="email"
                      placeholder="info@yourgmail.com"
                    ></input>
                    <button>
                      <img src={subscribearrow} alt="" />
                    </button>
                  </div>
                  <div className="social-icons d-flex justify-content-between">
                    <a href="https://t.me/deflyball_official" target="_blank">
                      <Icon
                        icon="akar-icons:telegram-fill"
                        color="white"
                        width="32"
                        height="32"
                        className="sociol-icons"
                      />
                    </a>
                    <a
                      href="https://www.instagram.com/deflyball/"
                      target="_blank"
                    >
                      <Icon
                        icon="akar-icons:instagram-fill"
                        color="white"
                        width="32"
                        height="32"
                        className="sociol-icons"
                      />
                    </a>
                    <a href="https://twitter.com/DeflyBall" target="_blank">
                      <Icon
                        icon="akar-icons:twitter-fill"
                        color="white"
                        width="32"
                        height="32"
                        className="sociol-icons"
                      />
                    </a>
                    <a
                      href="https://deflyball.medium.com/"
                      className="medium"
                      target="_blank"
                    >
                      <Icon
                        icon="akar-icons:medium-fill"
                        color="white"
                        width="32"
                        height="32"
                        className="sociol-icons"
                      />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/deflyball/"
                      className="medium"
                      target="_blank"
                    >
                      <Icon
                        icon="akar-icons:linkedin-box-fill"
                        color="white"
                        width="32"
                        height="32"
                        className="sociol-icons"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
