/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import Navbar from "../Navbar/Navbar";
import Web3 from "web3/dist/web3.min.js";
import "./Mystery.css";
import HeroCard1 from "../../Assets/Images/HeroCard3.png";
import { toast } from "react-toastify";
import { ethers } from "ethers";
import { Spinner } from "react-bootstrap";
import CountdownTimer from "../CountdownTimer";

const web3 = new Web3(window.ethereum);
const mystery_box_contractABI = require("../../abi/deflymysterycontract-abi.json");
const mystery_box_contractAddress = process.env.REACT_APP_MYSTERY_BOX_ADDRESS;
const defly_nft_contractABI = require("../../abi/deflymysterycontract-abi.json");
const defly_nft_contractAddress = process.env.REACT_APP_DEFLY_NFT_ADDRESS;
const defly_Token_contractABI = require("../../abi/deflytoken.json");
const defly_Token_contractAddress = process.env.REACT_APP_DEFLY_TOKEN_ADDRESS;
const busdToken_contractABI = require("../../abi/busdtoken.json");
const busdToken_contractAddress = process.env.REACT_APP_BUSD_TOKEN_ADDRESS;

const THREE_DAYS_IN_MS = 1662555600000;
// const NOW_IN_MS = new Date().getTime();

function Mysterybox(props) {
  const mysterBoxNumber = 4;
  const dateTimeAfterThreeDays = THREE_DAYS_IN_MS;
  //Loading
  let [loading, setLoading] = useState(false);
  //States
  const [curr, setCurr] = useState("busd");
  const [radio, setRadio] = useState("busd");
  const [isAllowed, setIsAllowed] = useState(0);

  const [totalQuantity, setTotalQuantity] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [allowanceChecked, setAllowanceChecked] = useState(false);
  const [IfMysteryBoxBought, setIfMysteryBoxBought] = useState(false);
  const [mysteryBoxContent, setMysteryBoxContent] = useState(null);
  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }
  // const item = props.history.location.state;
  //Approve
  const onApprove = async (item) => {
    if (loading == true) {
      toast(
        "✅ Please wait for completion of approval transaction, Buy button then will be enabled!!",
        {
          toastId: "wait1",
        }
      );
    } else {
      setLoading(true);
      window.busdToken_contract = await new web3.eth.Contract(
        busdToken_contractABI,
        busdToken_contractAddress
      );
      window.defly_Token_contract = await new web3.eth.Contract(
        defly_Token_contractABI,
        defly_Token_contractAddress
      );
      window.mystery_box_contract = await new web3.eth.Contract(
        mystery_box_contractABI,
        mystery_box_contractAddress
      );
      const contractAddress = window.mystery_box_contract._address;
      const allow_price = ethers.utils.parseEther("25");
      const allow_price_df = ethers.utils.parseEther("4500");
      if (curr == "busd") {
        try {
          window.busdToken_contract.methods
            .approve(contractAddress, allow_price)
            .send({ from: window.ethereum.selectedAddress })
            .on("transactionHash", async (hash) => {
              toast(
                "✅ Please wait for completion of approval transaction, Buy button then will be enabled!!"
              );
              setCurr("");
              await timeout(12000);
              setLoading(false);

              await setTimeout(() => {
                window.location.reload();
              }, 1000);
              setCurr("busd");
            })
            .on("error", (error) => {
              toast("Something went wrong while Approving");
              // props.history.push("/");
              window.location.reload(false);
            });
        } catch (error) {
          console.log("Error While Approving: " + error);
          toast("Error While Approving: " + error);
          setLoading(false);
        }
      } else {
        try {
          window.defly_Token_contract.methods
            .approve(contractAddress, allow_price_df)
            .send({ from: window.ethereum.selectedAddress })
            .on("transactionHash", async (hash) => {
              toast(
                "✅Please wait for completion of approval transaction, Buy button then be enabled!!"
              );
              setCurr("");
              await timeout(12000);
              setLoading(false);

              await setTimeout(() => {
                window.location.reload();
              }, 1000);
              setCurr("defly");
            })
            .on("error", (error) => {
              toast("Something went wrong while Approving");
              // props.history.push("/");
              setLoading(false);

              window.location.reload(false);
            });
        } catch (error) {
          console.log("Error While Approving: " + error);
          toast("Error While Approving: " + error);
          setLoading(false);
        }
      }
    }
  };
  //Claim Mystery Box
  const onClaim = async () => {
    if (loading == true) {
      toast("✅ Please wait for completion of claim transaction  ", {
        toastId: "wait1",
      });
    } else {
      setLoading(true);

      window.mystery_box_contract = await new web3.eth.Contract(
        mystery_box_contractABI,
        mystery_box_contractAddress
      );

      try {
        window.mystery_box_contract.methods
          .withdraw(mysterBoxNumber, window.ethereum.selectedAddress)
          .send({ from: window.ethereum.selectedAddress })
          .on("transactionHash", async (hash) => {
            toast("✅ Please wait for completion of claim transaction", {
              toastId: "claimToast",
            });
            setCurr("");
            await timeout(12000);
            setLoading(false);

            await setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .on("error", (error) => {
            toast("Something went wrong while Approving");
            // props.history.push("/");
            window.location.reload(false);
          });
      } catch (error) {
        console.log("Error While Approving: " + error);
        toast("Error While Approving: " + error);
        setLoading(false);
      }
    }
  };
  const onCheckBought = async () => {
    if (loading == true) {
      toast("✅ Please wait for completion of claim transaction  ", {
        toastId: "wait1",
      });
    } else {
      window.mystery_box_contract = await new web3.eth.Contract(
        mystery_box_contractABI,
        mystery_box_contractAddress
      );
      console.log(window.mystery_box_contract.methods);
      try {
        const boughtResult = await window.mystery_box_contract.methods
          .checkIfBought(mysterBoxNumber, window.ethereum.selectedAddress)
          .call();

        console.log(boughtResult);
        if (boughtResult) {
          onCheckWithdrawn();
        } else {
          toast("You have to buy this mystery box first.", {
            toastId: "wait1",
          });
        }
      } catch (error) {
        console.log("Error While Approving: " + error);
        toast("Error While Approving: " + error);
        setLoading(false);
      }
    }
  };
  const onCheckWithdrawn = async () => {
    if (loading == true) {
      toast("✅ Please wait for completion of claim transaction  ", {
        toastId: "wait1",
      });
    } else {
      window.mystery_box_contract = await new web3.eth.Contract(
        mystery_box_contractABI,
        mystery_box_contractAddress
      );
      console.log(window.mystery_box_contract.methods);
      try {
        const alreadyDrawnResult = await window.mystery_box_contract.methods
          .checkAlreadyWithdrawn(
            mysterBoxNumber,
            window.ethereum.selectedAddress
          )
          .call();

        console.log(alreadyDrawnResult);
        if (!alreadyDrawnResult) {
          onClaim();
        } else {
          toast("You already claimed this mystery box.", {
            toastId: "wait1",
          });
        }
      } catch (error) {
        console.log("Error While Approving: " + error);
        toast("Error While Approving: " + error);
        setLoading(false);
      }
    }
  };
  //Buy Mystery Box
  const onBuy = async (item) => {
    if (loading == true) {
      toast("✅ Please wait for completion of buy transaction", {
        toastId: "wait1",
      });
    } else {
      setLoading(true);
      window.mystery_box_contract = await new web3.eth.Contract(
        mystery_box_contractABI,
        mystery_box_contractAddress
      );
      if (curr == "busd") {
        try {
          window.mystery_box_contract.methods
            .buy(mysterBoxNumber, 0)
            .send({ from: window.ethereum.selectedAddress })
            .on("transactionHash", async (hash) => {
              toast(
                "✅ Check out your transaction on Bscscan: https://bscscan.com/address/" +
                  hash
              );
              await setTimeout(5000);
              setLoading(false);
              // props.history.push("/");
              window.location.reload(false);
            })
            .on("error", (error) => {
              toast("Something went wrong while buying");
              // props.history.push("/");
              setLoading(false);
              window.location.reload(false);
            });
        } catch (error) {
          console.log("Error While Buying: " + error);
          toast("Error While Buying: " + error);
          setLoading(false);
        }
      } else {
        try {
          window.mystery_box_contract.methods
            .buy(mysterBoxNumber, 1)
            .send({ from: window.ethereum.selectedAddress })
            .on("transactionHash", async (hash) => {
              toast(
                "✅ Check out your transaction on Bscscan: https://bscscan.com/address/" +
                  hash
              );
              await setTimeout(5000);
              setLoading(false);
              // props.history.push("/");
              window.location.reload(false);
            })
            .on("error", (error) => {
              console.log(error);
              toast("Something went wrong while buying");
              setLoading(false);
              // props.history.push('/');
              window.location.reload(false);
            });
        } catch (error) {
          console.log("Error While Buying: " + error);
          toast("Error While Buying: " + error);
          setLoading(false);
        }
      }
    }
  };
  const checkAllowance = async () => {
    if (loading == true) {
      // toast("✅ Please wait for completion of allowance checking", {
      //   toastId: "wait1",
      // });
    } else {
      setLoading(true);
      await window.ethereum.enable();
      console.log(curr);
      try {
        window.defly_Token_contract = await new web3.eth.Contract(
          defly_Token_contractABI,
          defly_Token_contractAddress
        );

        window.mystery_box_contract = await new web3.eth.Contract(
          mystery_box_contractABI,
          mystery_box_contractAddress
        );

        window.busdToken_contract = await new web3.eth.Contract(
          busdToken_contractABI,
          busdToken_contractAddress
        );

        if (curr == "busd") {
          setRadio("busd");
          const allowance_busd = await window.busdToken_contract.methods
            .allowance(
              window.ethereum.selectedAddress,
              mystery_box_contractAddress
            )
            .call();
          console.log(allowance_busd);
          if (allowance_busd >= 25000000000000000000) {
            setIsAllowed(1);
          } else {
            setIsAllowed(0);
          }
          setLoading(false);
        } else {
          setRadio("defly");
          const allowance = await window.defly_Token_contract.methods
            .allowance(
              window.ethereum.selectedAddress,
              mystery_box_contractAddress
            )
            .call();
          console.log(allowance);

          if (allowance >= 4500000000000000000000) {
            setIsAllowed(1);
          } else {
            setIsAllowed(0);
          }
          setLoading(false);
        }
        const box = await window.mystery_box_contract.methods
          .getMysteryBox(mysterBoxNumber)
          .call();
        setMysteryBoxContent(box);
        const checkIfBought = await window.mystery_box_contract.methods
          .checkIfBought(mysterBoxNumber, window.ethereum.selectedAddress)
          .call();
        setIfMysteryBoxBought(checkIfBought);
        const t_quantity = box.tokenIds.length / box.count;
        setTotalQuantity(t_quantity);
        setQuantity(t_quantity - box.participants.length);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    // window.scrollTo(0, 0);
    checkAllowance();
  }, [curr]);
  const onChangeRadioValue = (event) => {
    console.log(event.target.value);
    setCurr(event.target.value);
  };
  return (
    <div>
      <section className="mystery-box">
        <Navbar />
        <div className="container">
          <div className="row mt-5">
            <div className="mystry-left col-md-7 d-flex flex-column justify-content-around">
              <div className="mystery-head">
                <h1>DEFLY BALL</h1>
                <h1>MYSTERY BOX</h1>
              </div>

              <span className="Hero-variationss ">
                <div className="art">
                  <p className="art-counter">
                    {mysteryBoxContent ? (
                      mysteryBoxContent.tokenIds.length /
                      mysteryBoxContent.count
                    ) : (
                      <></>
                    )}
                  </p>
                  <p className="art-tag">Total</p>
                </div>
                <div className="art">
                  <p className="art-counter">
                    {mysteryBoxContent ? (
                      mysteryBoxContent.tokenIds.length /
                        mysteryBoxContent.count -
                      mysteryBoxContent.participants.length
                    ) : (
                      <></>
                    )}
                  </p>
                  <p className="art-tag">Remaining</p>
                </div>
                <div className="art">
                  <p className="art-counter">
                    {mysteryBoxContent ? (
                      mysteryBoxContent.participants.length
                    ) : (
                      <></>
                    )}
                  </p>
                  <p className="art-tag">Sold</p>
                </div>
              </span>

              {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quam
                viverra et, at nibh. Eget neque, vestibulum magna eget nunc eu.
                Venenatis egestas amet egestas erat imperdiet id curabitur. Odio
                euismod tortor molestie elementum malesuada amet integer
                maecenas. Ante mauris bibendum viverra consequat cursus. Ut
                magnis curabitur neque, consectetur bibendum vivamus. Ultrices
                diam in cras fringilla tellus viverra nisl, elementum eget.
                Accumsan fames scelerisque hendrerit id vitae quis senectus.
              </p> */}
            </div>
            <div className="mystry-img col-md-5 ">
              <img src="\Images\mysteryBox.png" alt="" className="img-fluid" />{" "}
              {/* {!IfMysteryBoxBought ? (
                // <div onChange={onChangeRadioValue}>
                //   <input
                //     type="radio"
                //     value="busd"
                //     name="currency"
                //     defaultChecked
                //   />{" "}
                //   <span> BUSD</span>{" "}
                //   <input
                //     className="form-check-input"
                //     type="radio"
                //     value="defly"
                //     name="currency"
                //   />{" "}
                //   <span> DEFLY</span>
                // </div>
                <div className="wrapper" onChange={onChangeRadioValue}>
                  <input
                    type="radio"
                    value="busd"
                    name="select"
                    id="option-1"
                    defaultChecked
                  />
                  <input
                    type="radio"
                    value="defly"
                    name="select"
                    id="option-2"
                  />
                  <label htmlFor="option-1" className="option option-1">
                    <div className="dot"></div>
                    <span>BUSD</span>
                  </label>
                  <label htmlFor="option-2" className="option option-2">
                    <div className="dot"></div>
                    <span>DEFLY</span>
                  </label>
                </div>
              ) : (
                <></>
              )}
              {loading ? (
                <Spinner
                  className="mysteryBoxButtonLoader"
                  animation="border"
                  role="status"
                  variant="warning"
                >
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                <></>
              )} */}
              {/* <CountdownTimer targetDate={dateTimeAfterThreeDays} /> */}
              {/* {IfMysteryBoxBought ? (
                <button className="claimRwd" disabled>
                  You already bought this box
                </button>
              ) : (
                <>
                  {isAllowed ? (
                    <button className="claimRwd" onClick={onBuy}>
                      Buy Now
                    </button>
                  ) : (
                    <button className="claimRwd" onClick={onApprove}>
                      Approve
                    </button>
                  )}
                </>
              )} */}
              {loading ? (
                <Spinner
                  className="mysteryBoxButtonLoader"
                  animation="border"
                  role="status"
                  variant="warning"
                >
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                <></>
              )}
              <button className="claimRwd" onClick={onCheckBought}>
                Claim
              </button>
              {/* <button className="claimRwd" disabled>
                Sale Ended
              </button> */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Mysterybox;
