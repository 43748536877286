import React, { useEffect, useState } from "react";
import "./Navbar.css";
import Modal from "react-bootstrap/Modal";
import logoImg from "../../Assets/Images/Logo.png";
import logoImgGif from "../../Assets/Images/logogif.gif";
import lock from "../../Assets/Images/dashicons_lock.png";
import metamask_icon from "../../Assets/Images/metamask_icon.svg";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

//Main Net
const bnbChainId = "0x38";
//Test Net
// const bnbChainId = "0x61";

function Navbar() {
  const [selectwallet, setShowSlctWallet] = useState(false);
  const SWhandleClose = () => setShowSlctWallet(false);
  const SWhandleShow = () => setShowSlctWallet(true);
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");

  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        const addressArray = await window.ethereum.request({
          method: "eth_requestAccounts",
        });

        const obj = {
          status: "👆🏽 Write a message in the text-field above.",
          address: addressArray[0],
        };
        return obj;
      } catch (err) {
        return {
          address: "",
          status: "😥 " + err.message,
        };
      }
    } else {
      toast.error(
        " You must install Metamask, a virtual Ethereum wallet, in your browser.",
        {
          toastId: "error1",
        }
      );
      return {
        address: "",
        status: (
          <span>
            <p>
              🦊
              <a target="_blank" href={`https://metamask.io/download.html`}>
                You must install Metamask, a virtual Ethereum wallet, in your
                browser.
              </a>
            </p>
          </span>
        ),
      };
    }
  };

  //On Metamask Account Changed....
  function addAccountChangedListener() {
    if (window.ethereum) {
      window.ethereum.on("chainChanged", (_chainId) => {
        window.location.reload();
        if (_chainId === bnbChainId) {
        } else {
          toast.error("Please connect to BSC main network", {
            toastId: "error1",
          });
        }
      });
    } else {
      toast.error(
        "You must install Metamask, a virtual Ethereum wallet, in your browser."
      );
    }
  }

  const getCurrentWalletConnected = async () => {
    if (window.ethereum) {
      try {
        const addressArray = await window.ethereum.request({
          method: "eth_accounts",
        });
        if (addressArray.length > 0) {
          return {
            address: addressArray[0],
            status: "👆🏽 Write a message in the text-field above.",
          };
        } else {
          toast.error("🦊 Connect to Metamask using the top right button.", {
            toastId: "error2",
          });
          return {
            address: "",
            status: "🦊 Connect to Metamask using the top right button.",
          };
        }
      } catch (err) {
        return {
          address: "",
          status: "😥 " + err.message,
        };
      }
    } else {
      toast.error(
        "You must install Metamask, a virtual Ethereum wallet, in your browser.!",
        {
          toastId: "error1",
        }
      );
      return {
        address: "",
        status: (
          <span>
            <p>
              🦊{" "}
              <a target="_blank" href={`https://metamask.io/download.html`}>
                You must install Metamask, a virtual Ethereum wallet, in your
                browser.
              </a>
            </p>
          </span>
        ),
      };
    }
  };
  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();

    setWallet(walletResponse.address);
    SWhandleClose();
  };
  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          setStatus("👆🏽 Write a message in the text-field above.");
          window.location.reload();
        } else {
          setWallet("");
          setStatus("🦊 Connect to Metamask using the top right button.");
        }
      });
    } else {
      toast.error(
        "You must install Metamask, a virtual Ethereum wallet, in your browser.!",
        {
          toastId: "error1",
        }
      );
      setStatus(
        <p>
          {" "}
          🦊{" "}
          <a target="_blank" href={`https://metamask.io/download.html`}>
            You must install Metamask, a virtual Ethereum wallet, in your
            browser.
          </a>
        </p>
      );
    }
  }
  const walletAtLoad = async () => {
    await window.ethereum.enable();
    const CurrentWallet = async () => {
      const { address } = await getCurrentWalletConnected();
      setWallet(address);
    };
    if (window.ethereum) {
      if (window.ethereum.chainId === bnbChainId) {
        CurrentWallet();
      } else {
        setWallet("");
        toast.error("Please connect to BSC main network", {
          toastId: "error1",
        });
      }
    }

    addWalletListener();
    addAccountChangedListener();
  };
  useEffect(() => {
    walletAtLoad();
  }, []);
  return (
    <div>
      <section className="Navbar">
        <nav className="navbar navbar-expand-lg">
          <div className="container">
            <div className="connect-wallet">
              <div className="nav-logo">
                <img src={logoImg} alt="" className="logo" />
                <div className="logogif">
                  <Link to="/">
                    <img src={logoImgGif} alt="" />
                  </Link>
                </div>
              </div>
            </div>

            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0  d-flex align-items-center">
                <li className="nav-item">
                  <Link
                    className="nav-link ball-nft"
                    aria-current="page"
                    to="/mysteryBox"
                  >
                    MYSTERY BOX
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link ball-nft"
                    aria-current="page"
                    to="/"
                  >
                    MARKETPLACE
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/mynft">
                    MY NFT’S
                  </Link>
                </li>
                <li className="nav-item  d-flex align-items-center">
                  <a className="nav-link upcoming-nav">STADIUMS</a>
                  <img src={lock} alt="" />
                </li>
                <li className="nav-item  d-flex align-items-center">
                  <a className="nav-link upcoming-nav">ACCESSORIES </a>
                  <img src={lock} alt="" />{" "}
                </li>{" "}
                {walletAddress && walletAddress != "" ? (
                  <div className="wallet" style={{ padding: "5px" }}>
                    {" "}
                    {"" +
                      String(walletAddress).substring(0, 6) +
                      "..." +
                      String(walletAddress).substring(38)}
                  </div>
                ) : (
                  <li className="nav-item wallet" onClick={SWhandleShow}>
                    <a className="nav-link " href="">
                      CONNECT WALLET
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </nav>
      </section>
      <Modal
        show={selectwallet}
        onHide={SWhandleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="selectPaymentMethod "
      >
        <Modal.Body className="modalBody">
          <div className="connect-wallet">
            <div className="connect-wallet-content ">
              <h1>DEFLY BALL</h1>
              <h2>MARETPLACE</h2>
              <a href="#" onClick={connectWalletPressed}>
                <img src={metamask_icon} alt="" /> Connect Metamask
              </a>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Navbar;
