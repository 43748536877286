import React from "react";
import "./BuyNft.css";
import Navbar from "../Components/Navbar/Navbar";
import Footer from "../Components/Footer/Footer";
import { useEffect } from "react";
import MarketNftDetail from "../Components/nftDetailN/marketNFTDetail";

function MarketBuyNft() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div>
      <section className="buy-nft">
        <Navbar />
        <MarketNftDetail />
        <Footer />
      </section>
    </div>
  );
}

export default MarketBuyNft;
