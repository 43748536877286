import React, { useEffect, useState } from "react";
import "./MyNFT.css";
import myNftHead from "../../Assets/Images/nftDetailPage/myNftHead.png";
import { superDogNFT } from "../Data.js";
import MarketProductCard from "../Market/MarketProductCard";
import MarketFoodKitCard from "../Market/MarketFoodKitCard";
import MarketTabFoodProduct from "../Market/MarketTabFoodProduct";
import Topnft from "../TopNft/Topnft";
import Footer from "../Footer/Footer";
import MyNftHero from "../Hero/MyNftHero";
import { getMyNFTsData, getMyNFTsDataNew } from "../../utils/interact";
import { Spinner } from "react-bootstrap";

function MyNFT() {
  //States
  let [loading, setLoading] = useState(true);
  const [myDogsNfts, setmyDogsNfts] = useState([]);
  const [noDataMsg, setnoDataMsg] = useState("Please wait...");

  const toggleLoading = async () => {
    setLoading(false);
  };
  const getMyNFTs = async () => {
    // setmyDogsNfts([]);
    let nftDataFromContract = await getMyNFTsData();
    let nftDataFromContractNew = await getMyNFTsDataNew();
    console.log(nftDataFromContractNew);
    console.log(nftDataFromContract);
    if (
      nftDataFromContract &&
      nftDataFromContract.success == false &&
      nftDataFromContractNew &&
      nftDataFromContractNew.success == false
    ) {
      toggleLoading();
      setnoDataMsg("No NFTs Found!");
    }
    if (nftDataFromContract || nftDataFromContractNew) {
      let nftTempArr = [
        ...nftDataFromContract.nftData,
        ...nftDataFromContractNew.nftData,
      ];
      console.log(nftTempArr)
      setmyDogsNfts(nftTempArr);
    } else {
      toggleLoading();
    }
  };
  useEffect(() => {
    getMyNFTs();
  }, []);
  useEffect(() => {}, [myDogsNfts]);

  return (
    <div>
      {loading ? (
        <Spinner
          className="loader"
          animation="border"
          role="status"
          variant="warning"
        >
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : (
        <></>
      )}
      <section className="myNft">
        <MyNftHero />
        <div className="container ">
          <div className="NftDetail-heading mt-5">
            <img src={myNftHead} alt="" />
          </div>
          <div className="my-nft-product">
            <div className="row">
              {myDogsNfts && myDogsNfts.length > 0 ? (
                myDogsNfts.map((item, index) => (
                  <div
                    onLoad={toggleLoading}
                    className="col-xl-3 col-lg-4 col-md-6 col-12 d-flex justify-content-center mb-3"
                    key={index}
                  >
                    <MarketProductCard cardData={item} />
                  </div>
                ))
              ) : (
                <div className="my-nft-hero-slogan">
                  <h2 style={{ color: "#fff" }}>{noDataMsg}</h2>
                </div>
              )}
              {/* <MarketTabFoodProduct /> */}
              {/* {superDogNFT.map((item) => {
                return (
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 d-flex justify-content-center mb-3">
                    <MarketProductCard cardData={item} />
                  </div>
                );
              })} */}
            </div>
          </div>

          {/* <Topnft /> */}
        </div>
        <Footer />
      </section>
    </div>
  );
}

export default MyNFT;
