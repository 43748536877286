import Web3 from "web3/dist/web3.min.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ethers } from "ethers";
import { addDoc, collection } from "firebase/firestore";
import { firestoredb } from "../firebase";
// import createBrowserHistory from "../utils/history";

const web3 = new Web3(window.ethereum);
// await window.ethereum.enable();
//Main Net
const bnbChainId = "0x38";
//Test Net
// const bnbChainId = "0x61";

const defly_nft_contractABI = require("../abi/deflyballnft-abi.json");
const defly_nft_contractAddress = process.env.REACT_APP_DEFLY_NFT_ADDRESS;

// BUSD Allowance Minimum Limit
const busd_token_allowance_limit = process.env.REACT_APP_BUSD_ALLOWANCE;
//DEFLY OWN Allowance Minimum Limit
const defly_own_token_allowance_limit =
  process.env.REACT_APP_DEFLYOWN_ALLOWANCE;
//Defly Mint Contract
const defly_mint_contractABI = require("../abi/defly-mint/deflymint-abi.json");
const defly_mint_contractAddress = process.env.REACT_APP_DEFLY_MINT_ADDRESS;

export const getMyNFTsDataNew = async () => {
  const web3 = new Web3(window.ethereum);
  await window.ethereum.enable();
  var nftData = [];
  try {
    window.defly_nft_contract_new = await new web3.eth.Contract(
      defly_mint_contractABI,
      defly_mint_contractAddress
    );
    // window.defly_nft_contract = await new web3.eth.Contract(
    //   defly_nft_contractABI,
    //   defly_nft_contractAddress
    // );
    
    const nfts_data_new = await window.defly_nft_contract_new.methods
      .balanceOf(window.ethereum.selectedAddress)
      .call();

    //loop for fetching tokenIDs
    for (var i = 0; i < nfts_data_new; i++) {
      const tokenIdofOwner = await window.defly_nft_contract_new.methods
        .tokenOfOwnerByIndex(window.ethereum.selectedAddress, i)
        .call();

      // const url = await window.defly_nft_contract.methods
      //   .tokenURIs(tokenIdofOwner-1)
      //   .call();
      const url = await window.defly_nft_contract_new.methods
        .tokenURI(tokenIdofOwner)
        .call();

      const response = await fetch(url);

      if (!response.ok) {
        console.log("Something went wrong!");
        throw new Error("Something went wrong!");
        // continue;
      }
      const data = await response.json();
      data.tokenId = tokenIdofOwner;
      data.newContract = true;
      nftData.push(data);

      if (i == nfts_data_new - 1) {
        return {
          success: true,
          msg: "Data Fetched Successfully",
          nftData: nftData,
        };
      }
    }
    if (nfts_data_new == 0) {
      return {
        success: false,
        msg: "No Data Fetched",
        nftData: [],
      };
    }
  } catch (err) {
    // toast.error("Something went wrong!", {
    //   toastId: "error1",
    // });
    console.log(err);
    return {
      success: false,
      msg: err,
      nftData: [],
    };
  }
};
export const getMyNFTsData = async () => {
  const web3 = new Web3(window.ethereum);
  await window.ethereum.enable();
  var nftData = [];
  try {
    // window.defly_nft_contract_new = await new web3.eth.Contract(
    //   defly_mint_contractABI,
    //   defly_mint_contractAddress
    // );
    window.defly_nft_contract = await new web3.eth.Contract(
      defly_nft_contractABI,
      defly_nft_contractAddress
    );
     
    const nfts_data_new = await window.defly_nft_contract.methods
      .balanceOf(window.ethereum.selectedAddress)
      .call();

    //loop for fetching tokenIDs
    for (var i = 0; i < nfts_data_new; i++) {
      const tokenIdofOwner = await window.defly_nft_contract.methods
        .tokenOfOwnerByIndex(window.ethereum.selectedAddress, i)
        .call();

      // const url = await window.defly_nft_contract.methods
      //   .tokenURIs(tokenIdofOwner-1)
      //   .call();
      const url = await window.defly_nft_contract.methods
        .tokenURI(tokenIdofOwner)
        .call();

      const response = await fetch(url);

      if (!response.ok) {
        console.log("Something went wrong!");
        throw new Error("Something went wrong!");
        // continue;
      }
      const data = await response.json();
      data.tokenId = tokenIdofOwner;
      data.newContract = false;

      nftData.push(data);

      if (i == nfts_data_new - 1) {
        return {
          success: true,
          msg: "Data Fetched Successfully",
          nftData: nftData,
        };
      }
    }
    if (nfts_data_new == 0) {
      return {
        success: false,
        msg: "No Data Fetched",
        nftData: [],
      };
    }
  } catch (err) {
    // toast.error("Something went wrong!", {
    //   toastId: "error1",
    // });
    console.log(err);
    return {
      success: false,
      msg: err,
      nftData: [],
    };
  }
};
export const getNFTsDetail = async (tid) => {
  const web3 = new Web3(window.ethereum);
  await window.ethereum.enable();
  var nftData = [];
  try {
    // window.defly_nft_contract = await new web3.eth.Contract(
    //   defly_mint_contractABI,
    //   defly_mint_contractAddress
    // );
    window.defly_nft_contract = await new web3.eth.Contract(
      defly_nft_contractABI,
      defly_nft_contractAddress
    );

    //loop for fetching tokenIDs

    const url = await window.defly_nft_contract.methods.tokenURI(tid).call();

    const response = await fetch(url);

    if (!response.ok) {
      // throw new Error("Something went wrong!");
      console.log("Something went wrong!");
    }
    const data = await response.json();

    data.tokenId = tid;
    //Settings data

    nftData.push(data);

    return {
      success: true,
      msg: "Data Fetched Successfully",
      nftData: nftData,
    };
  } catch (err) {
    toast.error("Something went wrong!", {
      toastId: "error1",
    });
    console.log(err);
    return {
      success: false,
      msg: err,
      nftData: [],
    };
  }
};
export const getNFTsDetailNew = async (tid) => {
  const web3 = new Web3(window.ethereum);
  await window.ethereum.enable();
  var nftData = [];
  try {
    window.defly_nft_contract = await new web3.eth.Contract(
      defly_mint_contractABI,
      defly_mint_contractAddress
    );
    // window.defly_nft_contract = await new web3.eth.Contract(
    //   defly_nft_contractABI,
    //   defly_nft_contractAddress
    // );

    //loop for fetching tokenIDs

    const url = await window.defly_nft_contract.methods.tokenURI(tid).call();

    const response = await fetch(url);

    if (!response.ok) {
      // throw new Error("Something went wrong!");
      console.log("Something went wrong!");
    }
    const data = await response.json();

    data.tokenId = tid;
    //Settings data

    nftData.push(data);

    return {
      success: true,
      msg: "Data Fetched Successfully",
      nftData: nftData,
    };
  } catch (err) {
    toast.error("Something went wrong!", {
      toastId: "error1",
    });
    console.log(err);
    return {
      success: false,
      msg: err,
      nftData: [],
    };
  }
};
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~NFT Detail page functions~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

//Defly BUSD TOKEN Contract
const defly_busd_token_contractABI = require("../abi/defly-mint/deflybusdtoken-abi.json");
const defly_busd_token_contractAddress =
  process.env.REACT_APP_DEFLY_BUSD_TOKEN_ADDRESS;

//Defly OWN TOKEN Contract
const defly_own_token_contractABI = require("../abi/defly-mint/deflyowntoken-abi.json");
const defly_own_token_contractAddress =
  process.env.REACT_APP_DEFLY_OWN_TOKEN_ADDRESS;

//Checking User Allowance
export const checkAllowance = async (curr, nftPrice) => {
  await window.ethereum.enable();

  // const allow_price = ethers.utils.parseEther(nftPrice);
  const allow_price = web3.utils.toWei(nftPrice, "ether");

  try {
    //Defly BUSD Token Contract
    window.deflyBusdToken_contract = await new web3.eth.Contract(
      defly_busd_token_contractABI,
      defly_busd_token_contractAddress
    );

    //Defly Own Token Contract
    window.deflyOwnToken_contract = await new web3.eth.Contract(
      defly_own_token_contractABI,
      defly_own_token_contractAddress
    );

    //Defly Mint Contract
    window.deflyMint_contract = await new web3.eth.Contract(
      defly_mint_contractABI,
      defly_mint_contractAddress
    );

    //On BUSD  Selected
    if (curr == 1) {
      const allowance_busd = await window.deflyBusdToken_contract.methods
        .allowance(window.ethereum.selectedAddress, defly_mint_contractAddress)
        .call();
      

      // if (allowance_busd >= busd_token_allowance_limit) {
      if (allowance_busd >= allow_price) {
        return true;
      } else {
        return false;
      }
    }

    //On DELFY  Selected
    else if (curr == 2) {
      const allowance_defly = await window.deflyOwnToken_contract.methods
        .allowance(window.ethereum.selectedAddress, defly_mint_contractAddress)
        .call();
     

      // if (allowance_defly >= defly_own_token_allowance_limit) {
      if (allowance_defly >= allow_price) {
        return true;
      } else {
        return false;
      }
    }
  } catch (err) {
    console.log(err);
    return false;
  }
};

//Approving User's Allowance
export const onApprove = async (curr, nftPrice) => {
  //Defly BUSD Token Contract
  window.deflyBusdToken_contract = await new web3.eth.Contract(
    defly_busd_token_contractABI,
    defly_busd_token_contractAddress
  );

  //Defly Own Token Contract
  window.deflyOwnToken_contract = await new web3.eth.Contract(
    defly_own_token_contractABI,
    defly_own_token_contractAddress
  );

  //Defly Mint Contract
  window.deflyMint_contract = await new web3.eth.Contract(
    defly_mint_contractABI,
    defly_mint_contractAddress
  );

  // const allow_price_busd = ethers.utils.parseEther("25");
  // const allow_price_df = ethers.utils.parseEther("4500");

  const allow_price_busd = ethers.utils.parseEther(nftPrice);
  const allow_price_df = ethers.utils.parseEther(nftPrice);

  //On BUSD Selected
  if (curr == 1) {
    

    try {
      window.deflyBusdToken_contract.methods
        .approve(defly_mint_contractAddress, allow_price_busd)
        .send({ from: window.ethereum.selectedAddress })
        .on("transactionHash", async (hash) => {
         
          toast(
            "✅ Please wait for completion of approval transaction, Buy button then will be enabled!!"
          );
          await setTimeout(() => {
            window.location.reload();
          }, 15000);
        })
        .on("error", (error) => {
          toast("Something went wrong while Approving");
          // props.history.push("/");
          window.location.reload(false);
        });
    } catch (error) {
      console.log("Error While Approving: " + error);
      toast("Error While Approving: " + error);
    }
  }
  //On DELFY  Selected
  else if (curr == 2) {
    

    try {
      window.deflyOwnToken_contract.methods
        .approve(defly_mint_contractAddress, allow_price_df)
        .send({ from: window.ethereum.selectedAddress })
        .on("transactionHash", async (hash) => {
          toast(
            "✅Please wait for completion of approval transaction, Buy button then be enabled!!"
          );
          await setTimeout(() => {
            window.location.reload();
          }, 15000);
        })
        .on("error", (error) => {
          toast("Something went wrong while Approving");
          window.location.reload(false);
        });
    } catch (error) {
      console.log("Error While Approving: " + error);
      toast("Error While Approving: " + error);
    }
  }
};
export const onBuy = async (dogId, nftUri, nftPrice, curr) => {
 

  //Defly Mint Contract
  window.deflyMint_contract = await new web3.eth.Contract(
    defly_mint_contractABI,
    defly_mint_contractAddress
  );
  var nftPriceTemp = "";
  if (curr == 3) {
    nftPriceTemp = web3.utils.toWei(String(nftPrice), "ether");
  } else {
    nftPriceTemp = nftPrice;
  }
 
  try {
    // window.deflyMint_contract.methods
    //   .safeMint(window.ethereum.selectedAddress, nftUri, nftPriceTemp, curr)
    //   .send({ from: window.ethereum.selectedAddress })
    //   .on("transactionHash", async (hash) => {
    //     toast(
    //       "✅ Please wait for completion of approval transaction, Buy button then will be enabled!!"
    //     );
    //     await setTimeout(() => {
    //       window.location.reload();
    //     }, 10000);
    //   })
    //   .on("error", (error) => {
    //     toast("Something went wrong while Approving");
    //     // props.history.push("/");
    //     window.location.reload(false);
    //   });
    if (curr == 3) {
      

      const txHash = await web3.eth.sendTransaction({
        to: defly_mint_contractAddress, // Required except during contract publications.
        from: window.ethereum.selectedAddress, // must match user's active address.
        value: web3.utils.toHex(nftPriceTemp),
        data: window.deflyMint_contract.methods
          .safeMint(window.ethereum.selectedAddress, nftUri, nftPriceTemp, curr)
          .encodeABI(), //make call to buy box
      });
      await addDoc(collection(firestoredb, "mintedDogs"), {
        id: dogId,
        minterAddress: window.ethereum.selectedAddress,
        mintDate: new Date(),
      })
        .then(async (docRef) => {
          window.location.reload();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      const priceTemp = ethers.utils.parseEther(nftPriceTemp.toString());
 
      const txHash = await web3.eth.sendTransaction({
        to: defly_mint_contractAddress, // Required except during contract publications.
        from: window.ethereum.selectedAddress, // must match user's active address.

        data: window.deflyMint_contract.methods
          .safeMint(window.ethereum.selectedAddress, nftUri, priceTemp, curr)
          .encodeABI(), //make call to buy box
      });
      await addDoc(collection(firestoredb, "mintedDogs"), {
        id: dogId,
        minterAddress: window.ethereum.selectedAddress,
        mintDate: new Date(),
      })
        .then(async (docRef) => {
          window.location.reload();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  } catch (error) {
    console.log("Error While Buying: ", error);
    toast("Error While Buying: ", error);
  }
};
export const onBuyFood = async (foodId, nftUri, nftPrice, curr) => {
  //Defly Mint Contract
  window.deflyMint_contract = await new web3.eth.Contract(
    defly_mint_contractABI,
    defly_mint_contractAddress
  );
  var nftPriceTemp = "";
  if (curr == 3) {
    nftPriceTemp = web3.utils.toWei(String(nftPrice), "ether");
  } else {
    nftPriceTemp = nftPrice;
  }
 
  try {
    if (curr == 3) {
      const txHash = await web3.eth.sendTransaction({
        to: defly_mint_contractAddress, // Required except during contract publications.
        from: window.ethereum.selectedAddress, // must match user's active address.
        value: web3.utils.toHex(nftPriceTemp),
        data: window.deflyMint_contract.methods
          .safeMint(window.ethereum.selectedAddress, nftUri, nftPriceTemp, curr)
          .encodeABI(), //make call to buy box
      });
      await addDoc(collection(firestoredb, "mintedFoods"), {
        id: foodId,
        minterAddress: window.ethereum.selectedAddress,
        mintDate: new Date(),
      })
        .then(async (docRef) => {
          window.location.reload();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      const priceTemp = ethers.utils.parseEther(nftPriceTemp.toString());
  
      const txHash = await web3.eth.sendTransaction({
        to: defly_mint_contractAddress, // Required except during contract publications.
        from: window.ethereum.selectedAddress, // must match user's active address.
        // value: web3.utils.toHex(nftPriceTemp),
        data: window.deflyMint_contract.methods
          .safeMint(window.ethereum.selectedAddress, nftUri, priceTemp, curr)
          .encodeABI(), //make call to buy box
      });
      await addDoc(collection(firestoredb, "mintedFoods"), {
        id: foodId,
        minterAddress: window.ethereum.selectedAddress,
        mintDate: new Date(),
      })
        .then(async (docRef) => {
          window.location.reload();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  } catch (error) {
    console.log("Error While Approving: ", error);
    toast("Error While Approving: ", error);
  }
};
