import React from "react";

import Hero from "../Components/Hero/Hero";
import Market from "../Components/Market/Market";

function LandingPage() {
  return (
    <div>
      <Hero />
      <Market />
    </div>
  );
}

export default LandingPage;
