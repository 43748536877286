import React, { useEffect, useState } from "react";
import "./Market.css";
import Footer from "../Footer/Footer";
import maketContentHead from "../../Assets/Images/marketHeading.png";
import maketContentHeadmob from "../../Assets/Images/marketHead-mob.png";
import superDog from "../../Assets/Images/superdog.png";
import foodkit from "../../Assets/Images/foodkit.png";
import activeFoodkit from "../../Assets/Images/activeFoodKit.png";
import stadium from "../../Assets/Images/stadium.png";
import Accessories from "../../Assets/Images/accessories.png";
import MarketTabProducts from "./MarketTabProducts";
import MarketTabFoodProduct from "./MarketTabFoodProduct";
import MarketProductCard from "./MarketProductCard";
import Topnft from "../TopNft/Topnft";
import { useRef } from "react";

function Market() {
  // const [Tab, setTab] = useState("activeSuperDog");
  const ref = useRef(null);
  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, []);
  return (
    <div>
      <section className="Market pt-120">
        <div className="container">
          <div className="market-content">
            <div className="market-content-head">
              <div className="main-tit-wrap2">
                <div className="line-before"></div>
                <h2 className="main-tit2">MarketPlace</h2>
                <div className="line-after"></div>
              </div>
            </div>

            <ul
              className="nav nav-pills mb-3 marketTabs col-lg-12 col-12 mx-auto"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active market-product-tab"
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                  // onClick={() => setTab("superDog")}
                >
                  <img
                    // src={Tab === "superDog" ? activeSuperDog : superDog}
                    src={superDog}
                    alt=""
                  />
                  <p className="caption">Super Dog</p>
                </button>
              </li>

              <li className="nav-item" role="presentation">
                <button
                  className="nav-link market-product-tab"
                  id="pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-profile"
                  type="button"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                  // onClick={() => setTab("foodKit")}
                >
                  <img
                    // src={Tab === "foodKit" ? activeFoodkit : foodkit}
                    src={foodkit}
                    alt=""
                  />
                  <p className="caption">Food Kit</p>
                </button>
              </li>

              <li className="nav-item" role="presentation">
                <button
                  className="nav-link market-product-tab"
                  id="pills-contact-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-contact"
                  type="button"
                  role="tab"
                  aria-controls="pills-contact"
                  aria-selected="false"
                  disabled
                >
                  <img src={stadium} alt="" />
                  <p className="caption">Stadium</p>
                </button>
              </li>

              <li className="nav-item" role="presentation">
                <button
                  className="nav-link market-product-tab"
                  id="pills-disabled-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-disabled"
                  type="button"
                  role="tab"
                  aria-controls="pills-disabled"
                  aria-selected="false"
                  disabled
                >
                  <img src={Accessories} alt="" />
                  <p className="caption">Accessories</p>
                </button>
              </li>
            </ul>

            <div className="tab-content" id="pills-tabContent" ref={ref}>
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
                tabIndex="0"
              >
                <MarketTabProducts />
              </div>
              <div
                className="tab-pane fade"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
                tabIndex="0"
              >
                <MarketTabFoodProduct />
              </div>
              <div
                className="tab-pane fade"
                id="pills-contact"
                role="tabpanel"
                aria-labelledby="pills-contact-tab"
                tabIndex="0"
              >
                ...
              </div>
              <div
                className="tab-pane fade"
                id="pills-disabled"
                role="tabpanel"
                aria-labelledby="pills-disabled-tab"
                tabIndex="0"
              >
                ...
              </div>
            </div>
          </div>
        </div>
        <br></br>
        {/* <Topnft /> */}
        <Footer />
      </section>
    </div>
  );
}

export default Market;
